import { useEffect, useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

export const useFacebookTokenExpiration = () => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedMerchant = useSelector(
      (state: any) => state.merchants.selectedMerchant
  );
  const [expirationPercentage, setExpirationPercentage] = useState<number>(0);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [isNearExpiration, setIsNearExpiration] = useState<boolean>(false);

  useEffect(() => {
    if (
      selectedMerchant?.facebookAccessTokenExpiry?.start &&
      selectedMerchant?.facebookAccessTokenExpiry?.end
    ) {
      const startOfDate = moment(selectedMerchant.facebookAccessTokenExpiry.start);
      const endDate = moment(selectedMerchant.facebookAccessTokenExpiry.end);
      const todayDate = moment();

      const totalDuration = moment(endDate).diff(startOfDate, "days");
      const elapsedTime = todayDate.diff(startOfDate, "days");
      
      const percentage = Math.min(100, Math.round((elapsedTime / totalDuration) * 100));
      setExpirationPercentage(percentage);

      if (percentage >= 100 || todayDate.isAfter(endDate)) {
        setIsExpired(true);
      } else if (percentage >= 80) {
        setIsNearExpiration(true);
      } else {
        setIsExpired(false);
        setIsNearExpiration(false);
      }
    }
  }, [selectedMerchant?.facebookAccessTokenExpiry, enqueueSnackbar]);

  return {
    expirationPercentage,
    isExpired,
    isNearExpiration
  };
}; 