import { useApi } from "./useAPI";

export const useFeatureFlag = () => {
  const { getFlag } = useApi();
  
  const isFeatureEnabled = (flagKey: string, defaultValue = false): boolean => {
    if (!getFlag) return defaultValue;
    const flagValue = getFlag(flagKey, defaultValue);
    return flagValue.value !== undefined ? flagValue.value : defaultValue;
  };
  
  return { isFeatureEnabled };
}; 