import { type Merchant } from "types/merchant";
import { useSelector } from "react-redux";
import { useApi } from "hooks/useAPI";
import { useEffect, useState } from "react";
import {
  CompareOption,
  SalesService,
  SalesSummaryData,
} from "services/SalesService";
import { getLocalTime } from "utils/getLocalTime";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ROASWidget } from "views/app-views/dashboards/google/HeaderWidget/ROASWidget";
import { AOVWidget } from "views/app-views/dashboards/google/HeaderWidget/AOVWidget";
import { CVRWidget } from "views/app-views/dashboards/google/HeaderWidget/CVRWidget";
import { CPCWidget } from "views/app-views/dashboards/google/HeaderWidget/CPCWidget";
export const HeaderWidgets = () => {
  const { get } = useApi();

  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const googleData = useSelector((state: any) => state.googleData.data);
  const {
    startDate,
    endDate,
    compareOption,
  }: { startDate: Date; endDate: Date; compareOption: CompareOption } =
    useSelector((state: any) => state.common);
  const [loading, setLoading] = useState<boolean>(false);

  const [summary, setSummary] = useState<SalesSummaryData | null>(null);


  useEffect(() => {
    const fetchSummary = async () => {
      const salesSummary = new SalesService(get);

      const { startDateString, endDateString } = getLocalTime(
        startDate,
        endDate
      );

      setLoading(true);
      const result = await salesSummary.getSummary({
        merchantId: selectedMerchant.id,
        start: startDateString,
        end: endDateString,
        compareOption: compareOption,
      });
      setLoading(false);
      setSummary(result);
    };

    if (selectedMerchant && startDate && endDate) {
      fetchSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant, startDate, endDate, compareOption]);

  return (
    <>
      <Grid item xs={12} sm={6} lg={3}>
        <VuiBox mb={3}>
          <ROASWidget
            title="ROAS"
            loading={loading}
            value={googleData?.roas || 0}
            compareValue={summary?.revenue.compareValue || 0}
          />
        </VuiBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <VuiBox mb={3}>
          <CPCWidget
            title="COST / CONV"
            loading={loading}
            googleData={googleData || { graphData: [] }}
            currency={selectedMerchant.currency || "USD"}
          />
        </VuiBox>
      </Grid>
     
      <Grid item xs={12} sm={6} lg={3}>
        <VuiBox mb={3}>
          <CVRWidget
            title="CVR"
            loading={loading}
            googleData={googleData || { graphData: [] }}
            currency={selectedMerchant.currency || "USD"}
          />
        </VuiBox>
      </Grid> 
      <Grid item xs={12} sm={6} lg={3}>
        <VuiBox mb={3}>
          <AOVWidget
            title="AOV"
            loading={loading}
            googleData={googleData || { graphData: [] }}
            currency={selectedMerchant.currency || "USD"}
          />
        </VuiBox>
      </Grid>
    </>
  );
};
