import React from "react";
import { useSelector } from "react-redux";
import VuiBox from "components/VuiBox";
import FacebookTokenExpirationAlert from "components/FacebookTokenExpirationAlert";

const GlobalAlerts: React.FC = () => {
  const selectedMerchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  if (!selectedMerchant?.id || !selectedMerchant?.facebookConnected) {
    return null;
  }

  return (
    <VuiBox width="100%">
      <FacebookTokenExpirationAlert />
    </VuiBox>
  );
};

export default GlobalAlerts; 