import { GetFunction } from "services/APIService";

interface GetGoogleROASQuery {
  merchantId: string;
  startDate: string;
  endDate: string;
}

export interface GetGoogleROASResponse {
  roas: number;
  compareValue: number;
}

export class GoogleAdsService {
  private get: GetFunction;

  constructor(getFn: GetFunction) {
    this.get = getFn;
  }

  async getROAS(params: GetGoogleROASQuery): Promise<GetGoogleROASResponse> {
    const { merchantId, startDate, endDate } = params;
    const queryParams = new URLSearchParams({
      merchantId,
      startDate,
      endDate,
    }).toString();

    const response = await this.get<GetGoogleROASResponse>(
      `google/roas?${queryParams}`
    );
    return response;
  }
}
