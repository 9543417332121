import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Stack,
} from "@mui/material";
import MerchantService from "services/MerchantService";
import {
  setMerchants,
  selectedMerchant as setSelectedMerchant,
} from "redux/actions";
import { useApi } from "hooks/useAPI";
import FacebookAttributionWindowClickSelector from "components/shared-components/FacebookAttributionWindowClickSelector";
import FacebookAttributionWindowViewSelector from "components/shared-components/FacebookAttributionWindowViewSelector";
import { Merchant } from "types/merchant";
import { useState } from "react";
import VuiButton from "components/VuiButton";
import FormField from "./components/FormField";
import { useSnackbar } from "notistack";

interface FacebookSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function FacebookSettingsModal({
  isOpen,
  onClose,
}: FacebookSettingsModalProps) {
  const { getToken } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [facebookAccountId, setFacebookAccountId] = useState<string>();
  const [facebookAttributionWindowClick, setFacebookAttributionWindowClick] =
    useState<string>();
  const [facebookAttributionWindowView, setFacebookAttributionWindowView] =
    useState<string>();

  const dispatch = useDispatch();
  const merchants = useSelector(
    (state: any) => state.merchants.merchant as Merchant[]
  );
  const selectedMerchant = useSelector(
    (state: any) => state.merchants.selectedMerchant as Merchant
  );

  const onUpdate = async () => {
    const token = getToken ? await getToken() : undefined;
    if (!token) return;

    try {
      const updatedValues = {
        facebookAccountId,
        facebookAttributionWindowClick,
        facebookAttributionWindowView,
        id: selectedMerchant.id,
      };

      const resp = await MerchantService.updateMerchant(updatedValues, token);

      enqueueSnackbar("Updated Successfully!", { variant: "success" });

      const updatedMerchants = merchants.map((m) =>
        m.id === resp.id ? { ...resp, roles: (m as any).roles } : m
      );

      dispatch(setMerchants(updatedMerchants));
      dispatch(setSelectedMerchant({ ...resp, roles: (selectedMerchant as any).roles }));
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="facebook-settings-dialog"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.87);",
          color: "white",
        },
      }}
    >
      <DialogTitle
        id="facebook-settings-dialog"
        sx={{ color: "white !important" }}
      >
        Edit Facebook Details
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
            <FacebookAttributionWindowClickSelector
              onChange={(value) => setFacebookAttributionWindowClick(value)}
            />
            <FacebookAttributionWindowViewSelector
              onChange={(value) => setFacebookAttributionWindowView(value)}
            />
            <FormField
              label="Facebook Account ID"
              placeholder="Facebook Account ID"
              onChange={(e) => setFacebookAccountId(e.target.value)}
              defaultValue={selectedMerchant.facebookAccountId}
            />
          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <VuiButton variant="outlined" onClick={onClose}>
          Cancel
        </VuiButton>
        {/* @ts-ignore */}
        <VuiButton variant="contained" onClick={onUpdate}>
          Update
        </VuiButton>
      </DialogActions>
    </Dialog>
  );
}
