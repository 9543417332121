import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  title: string;
  googleData: any;
  loading: boolean;
  currency: string;
}

export const CVRWidget = ({ googleData, loading, title }: Props) => {
  const totalConversionRate = googleData.conversionRate ? googleData.conversionRate : 0;
  return (
    <MiniStatisticsCard
      count={`${totalConversionRate}%`}
      title={{ text: title, fontWeight: "bold" }}
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
