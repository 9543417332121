import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  title: string;
  facebookData: any;
  loading: boolean;
}

export const CVRWidget = ({ facebookData, loading, title}: Props) => {
  let totalCVR = 0;
  if (facebookData && facebookData.graphData && facebookData.graphData.length > 0) {
    const facebookRows = facebookData.graphData.map((row: any) => {
      return {
        id: row._id,
        purchases: row.purchases,
        outboundClicks: row.outboundClicks,
        cvr: (row.purchases / (row.outboundClicks || 1)) * 100,
      };
    });
    facebookRows.forEach((row: any) => {
      totalCVR += row.cvr;
      });
      totalCVR = totalCVR / (facebookData.graphData.length || 1);
      totalCVR = Number(totalCVR.toFixed(2));
  }
  return (
    <MiniStatisticsCard
      count={`${totalCVR}%`}
      title={{ text: title, fontWeight: "bold" }}
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
