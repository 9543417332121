import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import MerchantService from "services/MerchantService";
import {
  setMerchants,
  selectedMerchant as setSelectedMerchant,
} from "redux/actions";
import { useApi } from "hooks/useAPI";
import { Merchant } from "types/merchant";
import { useSnackbar } from "notistack";
import VuiButton from "components/VuiButton";
import { useState } from "react";
import FormField from "./components/FormField";

interface GoogleSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function GoogleSettingsModal({
  isOpen,
  onClose,
}: GoogleSettingsModalProps) {
  const { getToken } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [googleAccountId, setGoogleAccountId] = useState<string>();

  const dispatch = useDispatch();
  const merchants = useSelector(
    (state: any) => state.merchants.merchant as Merchant[]
  );
  const selectedMerchant = useSelector(
    (state: any) => state.merchants.selectedMerchant as Merchant
  );

  const onUpdate = async () => {
    const token = getToken ? await getToken() : undefined;
    if (!token) return;

    try {
      const updatedValues = {
        googleAccountId,
        id: selectedMerchant.id,
      };

      const resp = await MerchantService.updateMerchant(updatedValues, token);

      enqueueSnackbar("Updated Successfully!", { variant: "success" });

      const updatedMerchants = merchants.map((m) =>
        m.id === resp.id ? { ...resp, roles: (m as any).roles } : m
      );

      dispatch(setMerchants(updatedMerchants));
      dispatch(setSelectedMerchant({ ...resp, roles: (selectedMerchant as any).roles }));
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="google-settings-dialog"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.87);",
          color: "white",
        },
      }}
    >
      <DialogTitle
        id="google-settings-dialog"
        sx={{ color: "white !important" }}
      >
        Edit Google Details
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={6}>
          <FormField
            label="Google Account ID"
            placeholder="Google Account ID"
            onChange={(e) => setGoogleAccountId(e.target.value)}
            defaultValue={selectedMerchant.googleAccountId}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <VuiButton variant="outlined" onClick={onClose}>
          Cancel
        </VuiButton>
        {/* @ts-ignore */}
        <VuiButton variant="contained" onClick={onUpdate}>
          Update
        </VuiButton>
      </DialogActions>
    </Dialog>
  );
}
