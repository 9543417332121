import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  title: string;
  value: number;
  compareValue: number;
  loading: boolean;
}

export const ROASWidget = ({ value, compareValue, loading, title }: Props) => {
  return (
    <MiniStatisticsCard
      title={{ text: title, fontWeight: "bold" }}
      count={value.toFixed(2)}
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
