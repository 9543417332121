import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  title: string;
  facebookData: any;
  loading: boolean;
  currency: string;
}

export const AOVWidget = ({ facebookData, loading, title, currency = "USD" }: Props) => {
  let totalAOV = 0;
  if (facebookData && facebookData.graphData && facebookData.graphData.length > 0) {
    const facebookRows = facebookData.graphData.map((row: any) => {
      return {
        aov:
          (parseFloat(row.purchaseRoas || 0) * parseFloat(row.totalSpend || 1)) /
          (row.purchases || 1),
      };
    });
    facebookRows.forEach((row: any) => {
      totalAOV += row.aov;
    });
    totalAOV = totalAOV / (facebookData.graphData.length || 1);
    totalAOV = Number(totalAOV.toFixed(2));
  }
  return (
    <MiniStatisticsCard
      count={`${totalAOV.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      })}`}
      title={{ text: title, fontWeight: "bold" }}
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
