import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  title: string;
  googleData: any;
  loading: boolean;
  currency: string;
}

export const AOVWidget = ({ googleData, loading, title, currency = "USD" }: Props) => {
  const totalAOV = googleData.aov ? googleData.aov : 0;
  return (
    <MiniStatisticsCard
      count={`${totalAOV.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      })}`}
      title={{ text: title, fontWeight: "bold" }}
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
