import { useEffect, useMemo } from "react";
import { subDays } from "date-fns";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setFacebookData,
  setFacebookDataPrevious,
  setGoogleData,
  setGoogleDataPrevious,
  setShopifyOrdersByDate,
  setActiveSubscription,
  setCommonProps,
  setTargets,
  setTotalSubscriptions,
  setCommonDates,
  setCompareOption,
  setShopifyOrdersCompareData,
} from "redux/actions";
import DataService from "services/DataService";
import { useApi } from "hooks/useAPI";
import { getLocalTime } from "utils/getLocalTime";
import VuiSelect from "components/VuiSelect";
import Stack from "@mui/material/Stack";
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { shortcutsItems } from "./shortcuts";

const DateFilter = (props: any) => {
  const {
    merchant,
    selectedMerchant,
    setShopifyOrdersByDate,
    setFacebookData,
    setGoogleData,
  } = props;
  const dispatch = useDispatch();
  const { startDate, endDate, compareOption } = useSelector(
    (state: any) => state.common
  );

  useEffect(() => {
    if (!startDate && !endDate) {
      dispatch(
        setCommonDates({
          startDate: subDays(new Date(), 7),
          endDate: subDays(new Date(), 1),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const { getToken } = useApi();

  const dateRange = useMemo(() => {
    if (startDate && endDate) {
      return {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      };
    }
    return {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
    };
  }, [startDate, endDate]);

  const calculateAmount = (total: any, refund: any) => {
    if (total?.length && refund?.length) {
      return total[0].totalAmount - refund[0].totalAmount;
    } else if (total?.length) {
      return total[0].totalAmount;
    }
    return 0;
  };

  const getFacebookData = (data: any) => {
    if (Object.keys(data).length) {
      const { totalSum, graphData } = data;

      let totalRoas = 0;
      if (graphData && graphData.length > 0) {
        graphData.forEach((item: any) => {
          totalRoas += item.purchaseRoas || 0;
        });
        totalRoas = totalRoas / graphData.length;
      }

      return {
        spend: totalSum.length ? totalSum[0].totalSpend : 0,
        roas: graphData.length ? totalRoas : 0,
        impressions: totalSum.length ? totalSum[0].totalImpressions : 0,
        graphData,
      };
    }
    return {};
  };

  const getGoogleData = (data: any) => {
    if (Object.keys(data).length) {
      const { totalSum, graphData } = data;

      return {
        spend: totalSum.length ? totalSum[0].totalSpend : 0,
        roas: totalSum.length ? totalSum[0].roas : 0,
        costPerConversion: totalSum.length ? totalSum[0].costPerConversion : 0,
        aov: totalSum.length ? totalSum[0].valueByConversion : 0,
        conversionRate: totalSum.length ? totalSum[0].conversionRate : 0,
        graphData,
      };
    }
    return {};
  };

  useEffect(() => {
    const fetchForUpdatedFilter = async () => {
      const token = getToken ? await getToken() : undefined;
      await onFilter(token);
    };
    fetchForUpdatedFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, selectedMerchant, compareOption]);

  const onFilter = async (token?: string) => {
    if (
      merchant.length &&
      selectedMerchant &&
      selectedMerchant.id &&
      dateRange?.startDate
    ) {
      setShopifyOrdersByDate({});
      setFacebookData({});
      setGoogleData({});

      const { startDateString, endDateString } = getLocalTime(
        dateRange.startDate,
        dateRange.endDate
      );

      const data: any = await DataService.getData(
        token,
        selectedMerchant.id,
        startDateString,
        endDateString,
        compareOption
      );
      const {
        shopify: shopifyData,
        refunds: refundsData,
        facebook: facebookData,
        google: googleData,
        cost: productCost,
        compareValues,
      } = data;
      const {
        totalSum,
        returningCustomers,
        graphData,
        newCustomersGraphData,
        returningCustomersGraphData,
        previousTotalSum,
        products,
      } = shopifyData;
      const { refundAmount, graphDataRefunds, previousRefundAmount, refunds } =
        refundsData;
      dispatch(setShopifyOrdersCompareData(compareValues));
      const filteredObject = {
        totalAmount: calculateAmount(totalSum, refundAmount),
        previousTotalAmount: calculateAmount(
          previousTotalSum,
          previousRefundAmount
        ),
        averageAmount: totalSum.length ? totalSum[0].averageAmount : 0,
        ordersCount: totalSum.length ? totalSum[0].count : 0,
        returningCustomers: returningCustomers.length
          ? returningCustomers[0].count
          : 0,
        returningCustomersAmount: returningCustomers.length
          ? returningCustomers[0].totalAmount
          : 0,
        products,
        graphData,
        newCustomersGraphData,
        returningCustomersGraphData,
        graphDataRefunds,
        refunds,
        totalCost: productCost?.totalCost?.length
          ? productCost.totalCost[0].totalCost
          : 0,
      };
      setShopifyOrdersByDate(filteredObject);
      setFacebookData(getFacebookData(facebookData));
      setGoogleData(getGoogleData(googleData));
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        name="allowedRange"
        value={[
          dateRange.startDate ? new Date(dateRange.startDate) : null,
          dateRange.endDate ? new Date(dateRange.endDate) : null,
        ]}
        onChange={(newValue) => {
          if (newValue[0] && newValue[1]) {
            dispatch(
              setCommonDates({
                startDate: newValue[0],
                endDate: newValue[1],
              })
            );
          }
        }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
            sx: {
              color: "white",
              "& .MuiButtonBase-root": {
                color: "white !important",
              },
            },
          },
          actionBar: { actions: [] },
          layout: {
            sx: {
              backgroundColor: "#0f1535",
              "& .MuiPickersArrowSwitcher-root": {
                "& .MuiIconButton-root": {
                  color: "white !important",
                },
              },
              "& .MuiPickersDay-root": {
                color: "white !important",
                "&.Mui-selected": {
                  backgroundColor: "#1A73E8 !important",
                  "&:hover": {
                    backgroundColor: "#1557B0 !important",
                  },
                },
              },
              "& .css-159dkua-MuiDateRangePickerDay-root": {
                color: "#fff",
                backgroundColor: "rgba(26, 115, 232, 0.12)",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                color: "white !important",
              },
              "& .MuiPickersCalendarHeader-label": {
                color: "white !important",
              },
              "& .MuiPickersYear-yearButton": {
                color: "white !important",
              },
              "& .MuiPickersMonth-monthButton": {
                color: "white !important",
              },
              "& .MuiTypography-root": {
                color: "white !important",
              },
            },
          },
          textField: {
            sx: {
              "& .MuiOutlinedInput-root": {
                padding: "0px",
                backgroundColor: "#0f1535 !important",
                border: "none !important",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6b7280 !important",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#718096 !important",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0.5px solid #4a5568 !important",
                borderRadius: "0.9375rem !important",
              },
            },
            inputProps: {
              sx: {
                width: "188px !important",
                color: "#a0aec0 !important",
                padding: "8px 14px",
                fontSize: "12px !important",
                fontWeight: "400 !important",
              },
            },
          },
        }}
      />
      <VuiSelect
        //@ts-ignore
        defaultValue={{ label: compareOption, value: compareOption }}
        options={[
          { label: "Time", value: "time" },
          { label: "Targets", value: "targets" },
        ]}
        label="Compare Option"
        onChange={(value: { label: string; value: string }) =>
          dispatch(setCompareOption(value.value))
        }
      />
    </Stack>
  );
};

const mapStateToProps = ({
  merchants,
  shopifyOrders,
  common,
  googleData,
  auth,
}: any) => {
  const { merchant, selectedMerchant } = merchants;
  const { totalAmount, averageAmount, ordersCount } = shopifyOrders;
  const { datePreset, startDate, endDate, compareWith } = common;
  const { data: googleAdsData } = googleData;
  const { user } = auth;

  return {
    googleAdsData,
    merchant,
    selectedMerchant,
    totalAmount,
    averageAmount,
    ordersCount,
    datePreset,
    startDate,
    endDate,
    compareWith,
    user,
  };
};

const mapDispatchToProps = {
  setShopifyOrdersByDate,
  setFacebookData,
  setFacebookDataPrevious,
  setCommonProps,
  setGoogleData,
  setTotalSubscriptions,
  setActiveSubscription,
  setTargets,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
