import { type Merchant } from "types/merchant";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useApi } from "hooks/useAPI";
import { useEffect, useMemo, useState } from "react";
import { SalesService } from "services/SalesService";
import { getLocalTime } from "utils/getLocalTime";

import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import VuiSelect from "components/VuiSelect";
import VuiTypography from "components/VuiTypography";

enum ChartType {
  COUNT = "count",
  REVENUE = "revenue",
}

type ChartData = {
  key: string;
  value: number;
  name: string;
}[];

export const TopVariantsChart = () => {
  const { get } = useApi();
  const { gradients } = colors;
  const { cardContent } = gradients;
  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );
  const { startDate, endDate } = useSelector((state: any) => state.common);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChartData>([]);
  const [chartType, setChartType] = useState<ChartType>(ChartType.COUNT);

  useEffect(() => {
    const fetchData = async () => {
      const salesService = new SalesService(get);
      const { startDateString, endDateString } = getLocalTime(
        startDate,
        endDate
      );

      setLoading(true);
      try {
        if (chartType === ChartType.COUNT) {
          const result = await salesService.getTopVariantsByCount({
            merchantId: selectedMerchant.id,
            start: startDateString,
            end: endDateString,
          });
          setData(
            result.data.map((item) => ({
              key: item.id,
              value: item.count,
              name: item.name,
            }))
          );
        } else {
          const result = await salesService.getTopVariantsByRevenue({
            merchantId: selectedMerchant.id,
            start: startDateString,
            end: endDateString,
          });
          setData(
            result.data.map((item) => ({
              key: item.id,
              value: item.revenue,
              name: item.name,
            }))
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    if (selectedMerchant && startDate && endDate) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant, startDate, endDate, chartType]);

  const extra = (
    <VuiSelect
      //@ts-ignore
      defaultValue={{
        value: chartType,
        label: chartType === ChartType.COUNT ? "Count" : "Revenue",
      }}
      onChange={(option: any) => setChartType(option.value)}
      options={[
        { value: ChartType.COUNT, label: "Count" },
        { value: ChartType.REVENUE, label: "Revenue" },
      ]}
    />
  );

  const title = useMemo(() => {
    if (loading) {
      return "Loading Top Variants";
    }
    switch (chartType) {
      case ChartType.COUNT:
        const totalCount = data.reduce((sum, item) => sum + item.value, 0);
        return `Top Variants by Count: ${totalCount.toLocaleString()}`;
      case ChartType.REVENUE:
        const totalRevenue = data.reduce((sum, item) => sum + item.value, 0);
        return `Top Variants by Revenue: ${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: selectedMerchant.currency,
        }).format(totalRevenue)}`;
      default:
        return "Top Variants";
    }
  }, [data, loading, chartType, selectedMerchant.currency]);

  const chartData = useMemo(() => {
    return {
      series: [
        {
          name: chartType === ChartType.COUNT ? "Count" : "Revenue",
          data: data.map((item) => item.value),
        },
      ],
      options: {
        chart: {
          type: "bar",
          background: "transparent",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 6,
            columnWidth: "60%",
            borderRadiusApplication: "end",
          },
        },
        xaxis: {
          categories: data.map((item) => {
            return item?.name || "Unnamed";
          }),
          labels: {
            show: true,
            style: {
              colors: "#FFFFFF",
              fontSize: "12px",
            },
            trim: false,
            hideOverlappingLabels: true,
            rotate: 0,
            maxHeight: 100,
            formatter: function (value: string) {
              // Split long text into multiple lines
              const maxCharsPerLine = 15;
              if (value.length <= maxCharsPerLine) return value;
              if (!value) return "";

              const words = value?.split(" ");
              let lines = [];
              let currentLine = "";

              words.forEach((word: string) => {
                if ((currentLine + " " + word).length <= maxCharsPerLine) {
                  currentLine += (currentLine ? " " : "") + word;
                } else {
                  lines.push(currentLine);
                  currentLine = word;
                }
              });
              if (currentLine) {
                lines.push(currentLine);
              }

              // return as array to add new line
              return lines;
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "12px",
            },
          },
        },
        grid: {
          show: false,
        },
        colors: ["#fff"],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          theme: "dark",
        },
      },
    };
  }, [data, chartType]);

  return (
    <Card>
      <VuiBox
        mb="5px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          {title}
        </VuiTypography>
        {extra}
      </VuiBox>
      <VuiBox>
        <VuiBox
          mb="24px"
          height="320px"
          sx={{
            background: linearGradient(
              cardContent.main,
              cardContent.state,
              parseFloat(cardContent.deg)
            ),
            borderRadius: "20px",
          }}
        >
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900" />
            </div>
          ) : (
            <Chart
              // @ts-ignore
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={320}
            />
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};
