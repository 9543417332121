import {
  AddCircleOutline as AddCircleOutlineIcon,
  Save as SaveIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useSnackbar } from "notistack";

import Loading from "components/shared-components/Loading";
import { useApi } from "hooks/useAPI";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TargetsService } from "services/TargetsService";
import { Merchant } from "types/merchant";
import {
  IMonthlyTarget,
  TargetFields,
  TargetValues,
  titleForField,
} from "types/targets";
import { getLocalTime } from "utils/getLocalTime";
import { SetDailyTargetsModal } from "./SetDailyTargetsModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";
import VuiInput from "components/VuiInput";
import AccessDeniedCard from "components/shared-components/AccessDeniedCard";
import { hasAdminAccess } from "utils/roleAccess";
const now = new Date();

const MonthCard: FC<{
  month: number;
  record: any;
  monthlyTargets: IMonthlyTarget[];
  editedRows: Record<number, TargetValues>;
  onSave: (month: number) => void;
  onCancel: (month: number) => void;
  onCreate: (month: number) => void;
  onRowClick: (record: any) => void;
  onInputChange: (
    month: number,
    field: keyof TargetValues,
    value: string
  ) => void;
}> = ({
  month,
  record,
  monthlyTargets,
  editedRows,
  onSave,
  onCancel,
  onCreate,
  onRowClick,
  onInputChange,
}) => {
  const hasTarget = monthlyTargets.some(
    (t) => new Date(t.date).getUTCMonth() === record.month
  );

  return (
    <Card
      sx={{
        p: 2,
        backgroundColor: "rgba(0,0,0,0.2)",
        "&:hover": { backgroundColor: "rgba(0,0,0,0.3)" },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VuiBox display="flex" alignItems="center" gap={1}>
            <VuiTypography
              onClick={() => onRowClick(record)}
              sx={{ cursor: "pointer", color: "white !important" }}
            >
              {new Date(now.getFullYear(), month).toLocaleString("default", {
                month: "long",
              })}
            </VuiTypography>
            {hasTarget && editedRows[month] && (
              <>
                <IconButton
                  size="small"
                  sx={{ color: "white !important" }}
                  onClick={() => onSave(month)}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  size="small"
                  sx={{ color: "white !important" }}
                  onClick={() => onCancel(month)}
                >
                  <CloseIcon />
                </IconButton>
              </>
            )}
            {!hasTarget && (
              <IconButton
                size="small"
                sx={{ color: "white !important" }}
                onClick={() => onCreate(month)}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            )}
          </VuiBox>
        </Grid>
        {hasTarget &&
          Object.values(TargetFields).map((key) => (
            <Grid item xs={3} key={key}>
              <VuiTypography variant="caption" color="white">
                {titleForField(key)}
              </VuiTypography>
              <VuiInput
                //@ts-ignore
                value={
                  editedRows[month]?.[key as keyof TargetValues] !== undefined
                    ? editedRows[month][key as keyof TargetValues]
                    : record[key] || 0
                }
                onChange={(e: any) =>
                  onInputChange(
                    month,
                    key as keyof TargetValues,
                    e.target.value
                  )
                }
                type="number"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.23)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.main",
                    },
                  },
                }}
              />
            </Grid>
          ))}
      </Grid>
    </Card>
  );
};

const Targets = () => {
  const { get, post } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [monthlyTargets, setMonthlyTargets] = useState<IMonthlyTarget[]>([]);
  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );
  const userRole = useSelector((state: any) => state.merchants.selectedMerchant.roles?.name);
  const hasAccess = hasAdminAccess(userRole);
  const [loading, setLoading] = useState<boolean>(false);
  const [editedRows, setEditedRows] = useState<Record<number, TargetValues>>(
    {}
  );
  const [originalValues, setOriginalValues] = useState<
    Record<number, TargetValues>
  >({});
  const [selectedTarget, setSelectedTarget] = useState<IMonthlyTarget | null>(
    null
  );
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(new Date(currentYear, 11, 31));

  const fetchMonthlyTargets = async () => {
    if (!hasAccess) return;

    const targetsService = new TargetsService(get, post);
    const { startDateString, endDateString } = getLocalTime(startDate, endDate);
    setLoading(true);
    const result = await targetsService.getMonthlyTargets({
      merchantId: selectedMerchant.id,
      startDate: startDateString,
      endDate: endDateString,
    });
    setLoading(false);
    setMonthlyTargets(result.data);
  };

  useEffect(() => {
    fetchMonthlyTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant, startDate, endDate]);

  const handleInputChange = (
    month: number,
    field: keyof TargetValues,
    value: string
  ) => {
    const numValue = parseFloat(value) || 0;
    setEditedRows((prev) => ({
      ...prev,
      [month]: {
        ...(prev[month] || {}),
        [field]: numValue,
      },
    }));
  };

  const handleSaveRow = async (month: number) => {
    const monthsSinceStart = month - startDate.getMonth();
    const targetYear = startDate.getFullYear() + (monthsSinceStart < 0 ? 1 : 0);
    const targetDate = new Date(targetYear, month);

    const targetsService = new TargetsService(get, post);
    await targetsService.setMonthlyTarget({
      merchantId: selectedMerchant.id,
      month: (targetDate.getMonth() + 1).toString(),
      year: targetDate.getFullYear().toString(),
      values: editedRows[month] as TargetValues,
    });

    // Clear edited state for this row
    setEditedRows((prev) => {
      const newState = { ...prev };
      delete newState[month];
      return newState;
    });
    setOriginalValues((prev) => {
      const newState = { ...prev };
      delete newState[month];
      return newState;
    });
    await fetchMonthlyTargets();
    enqueueSnackbar("Monthly targets updated", {
      variant: "success",
      autoHideDuration: 3000,
    });
  };

  const handleCancelRow = (month: number) => {
    setEditedRows((prev) => {
      const newState = { ...prev };
      delete newState[month];
      return newState;
    });
  };

  const handleRowClick = (record: any) => {
    const target = monthlyTargets.find(
      (t) => new Date(t.date).getUTCMonth() === record.month
    );
    if (target) {
      setSelectedTarget(target);
    }
  };

  const handleYearChange = (year: string) => {
    const yearNum = parseInt(year);
    setSelectedYear(yearNum);
    setStartDate(new Date(yearNum, 0, 1));
    setEndDate(new Date(yearNum, 11, 31));
  };

  const data = Array.from({ length: 12 }, (_, index) => {
    const target = monthlyTargets?.find((t) => {
      const targetMonth = new Date(t.date).getUTCMonth() + 1;
      return targetMonth === index + 1;
    });

    return {
      key: index,
      month: index,
      ...Object.values(TargetFields).reduce(
        (acc, field) => ({
          ...acc,
          [field]: target ? target[field as keyof TargetValues] || 0 : 0,
        }),
        {}
      ),
    };
  });

  const handleCreateTargets = async (month: number) => {
    const monthsSinceStart = month - startDate.getMonth();
    const targetYear = startDate.getFullYear() + (monthsSinceStart < 0 ? 1 : 0);
    const targetDate = new Date(targetYear, month);

    // Get previous month's values
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousTarget = monthlyTargets.find(
      (t) => new Date(t.date).getMonth() === previousMonth
    );

    // Use previous month's values or default to 0
    const monthValues: TargetValues = Object.keys(TargetFields).reduce(
      (acc, key) => ({
        ...acc,
        [key]: previousTarget
          ? previousTarget[key as keyof TargetValues] || 0
          : 0,
      }),
      {} as TargetValues
    );

    const targetsService = new TargetsService(get, post);
    await targetsService.setMonthlyTarget({
      merchantId: selectedMerchant.id,
      month: (targetDate.getMonth() + 1).toString(),
      year: targetDate.getFullYear().toString(),
      values: monthValues,
    });

    // Refresh the targets
    const { startDateString, endDateString } = getLocalTime(startDate, endDate);
    const result = await targetsService.getMonthlyTargets({
      merchantId: selectedMerchant.id,
      startDate: startDateString,
      endDate: endDateString,
    });
    setMonthlyTargets(result.data);
    enqueueSnackbar(
      `Monthly target created for ${targetDate.toLocaleString("default", {
        month: "long",
      })}`,
      {
        variant: "success",
        autoHideDuration: 3000,
      }
    );
  };

  const handleDailyTargetsSaved = async () => {
    setSelectedTarget(null);
    await fetchMonthlyTargets();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VuiBox mb={3} p={1}>
              {!hasAccess ? (
                <AccessDeniedCard
                  title="Access Denied"
                  message="Only owners can access and manage targets of selected merchant. Please contact your administrator for access."
                />
              ) : (
                <>
                  <VuiTypography
                    variant="h2"
                    textTransform="capitalize"
                    fontWeight="bold"
                    color="white"
                  >
                    Monthly Targets
                  </VuiTypography>

                  <VuiBox p={3}>
                    <Tabs
                      value={selectedYear.toString()}
                      onChange={(_, value) => handleYearChange(value)}
                      textColor="primary"
                      indicatorColor="primary"
                    >
                      <Tab
                        label={currentYear.toString()}
                        value={currentYear.toString()}
                      />
                      <Tab
                        label={(currentYear + 1).toString()}
                        value={(currentYear + 1).toString()}
                      />
                    </Tabs>

                    {loading ? (
                      <Loading />
                    ) : (
                      <Grid container spacing={3}>
                        {data.map((record) => (
                          <Grid item xs={12} key={record.key}>
                            <MonthCard
                              month={record.month}
                              record={record}
                              monthlyTargets={monthlyTargets}
                              editedRows={editedRows}
                              onSave={handleSaveRow}
                              onCancel={handleCancelRow}
                              onCreate={handleCreateTargets}
                              onRowClick={handleRowClick}
                              onInputChange={handleInputChange}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </VuiBox>
                </>
              )}
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>

      <SetDailyTargetsModal
        monthlyTarget={selectedTarget || undefined}
        isOpen={!!selectedTarget}
        onClose={() => setSelectedTarget(null)}
        onSave={handleDailyTargetsSaved}
      />

      <Footer />
    </DashboardLayout>
  );
};

export default Targets;
