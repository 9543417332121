import { Button, Card, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Box } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useState } from "react";
import moment from "moment-timezone";

interface Props {
  title: string;
  googleData: any;
  loading: boolean;
  timezone?: string;
  currency?: string;
}

const toDecimal = (value: any) => {
  if (!value && value !== 0) return 0;
  return Number(parseFloat(value).toFixed(2));
};

const checkForAverage = (avg: number, current: number) => {
  if (current > avg) {
    return "rgba(0, 255, 0, 0.2) !important";
  } else if (current < avg) {
    return "rgba(255, 0, 0, 0.2) !important";
  }
  return "transparent !important";
};

const formatDate = (dateString: string, timezone?: string) => {
  if (dateString === 'Total') return dateString;
  
  try {
    if (timezone) {
      return moment(dateString).tz(timezone).format('MMM DD, YYYY');
    }
    return moment(dateString).format('MMM DD, YYYY');
  } catch (error) {
    return dateString;
  }
};

const formatCurrency = (value: number, currencyCode: string = 'USD'): string => {
  return toDecimal(value).toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
  });
};

export const GoogleOverview = ({ googleData, loading, title, timezone, currency }: Props) => {
    const [heatmapOption, setHeatmapOption] = useState(false);

    if (!googleData || !googleData.graphData || !Array.isArray(googleData.graphData)) {
        return (
            <Card>
                <VuiBox p={3}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                        <VuiTypography variant="lg" color="white" fontWeight="bold">
                            {title || "Google Overview"}
                        </VuiTypography>
                    </VuiBox>
                    <VuiBox display="flex" justifyContent="center" alignItems="center" height="200px">
                        <VuiTypography variant="button" color="text">
                            {loading ? "Loading data..." : "No Google data available"}
                        </VuiTypography>
                    </VuiBox>
                </VuiBox>
            </Card>
        );
    }

    let googleRows = googleData.graphData.map((row: any) => {
        return {
          id: row._id,
          date: row._id,
          impressions: row.impressions,
          cost: row.cost,
          clicks: row.clicks,
          cpc: row.cpc,
          ctr: row.ctr,
          conversions: row.conversions,
          costPerConversion: row.costPerConversion,
          conversionRate: row.conversionRate,
          conversionValue: row.conversionValue,
          roas: row.roas,
          valueByConversion: row.valueByConversion
        };
      });
      googleRows = googleRows.sort((a: any, b: any) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      });
    
      const totalObject = {
        id: "Total",
        date: "Total",
        impressions: 0,
        cost: 0,
        clicks: 0,
        cpc: 0,
        ctr: 0,
        conversions: 0,
        costPerConversion: 0,
        conversionRate: 0,
        conversionValue: 0,
        roas: 0,
        valueByConversion: 0
      };
      googleData.graphData.forEach((row: any) => {
        totalObject.impressions += row.impressions;
        totalObject.cost += row.cost;
        totalObject.clicks += row.clicks;
        totalObject.cpc += row.cpc;
        totalObject.ctr += row.ctr;
        totalObject.conversions += row.conversions;
        totalObject.costPerConversion += row.costPerConversion;
        totalObject.conversionRate += row.conversionRate;
        totalObject.conversionValue += row.conversionValue;
        totalObject.roas += row.roas;
        totalObject.valueByConversion += row.valueByConversion;
      });
    
      totalObject.cpc = totalObject.cpc / googleData.graphData.length;
      totalObject.ctr = totalObject.ctr / googleData.graphData.length;
      totalObject.costPerConversion = totalObject.costPerConversion / googleData.graphData.length;
      totalObject.conversionRate = totalObject.conversionRate / googleData.graphData.length;
      totalObject.roas = totalObject.roas / googleData.graphData.length;
      totalObject.valueByConversion = totalObject.valueByConversion / googleData.graphData.length;
    
      if (googleRows.length > 1) {
        googleRows.push(totalObject);
      }

      googleRows = googleRows.map((v: any) => ({
        ...v,
        totalImpressions: totalObject.impressions,
        totalCost: totalObject.cost,
        totalClicks: totalObject.clicks,
        avgCPC: totalObject.cpc,
        avgCTR: totalObject.ctr,
        totalConversions: totalObject.conversions,
        avgCostPerConversion: totalObject.costPerConversion,
        avgConversionRate: totalObject.conversionRate,
        totalConversionValue: totalObject.conversionValue,
        avgROAS: totalObject.roas,
        avgValueByConversion: totalObject.valueByConversion
      }));

      const switchHeatmap = () => setHeatmapOption((val) => !val);

      const columns = [
        { id: 'date', label: 'Date', width: '100px', align: 'left' },
        { id: 'impressions', label: 'Impressions', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'cost', label: 'Cost', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'clicks', label: 'Clicks', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'cpc', label: 'CPC', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'ctr', label: 'CTR', width: '140px', format: (value: number) => `${toDecimal(value).toLocaleString()}%` },
        { id: 'conversions', label: 'Conversions', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'costPerConversion', label: 'Cost Per Conversion', width: '160px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'conversionRate', label: 'Conversion Rate', width: '140px', format: (value: number) => `${toDecimal(value).toLocaleString()}%` },
        { id: 'conversionValue', label: 'Conv Value', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'roas', label: 'ROAS', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'valueByConversion', label: 'Value Per Conversion', width: '180px', format: (value: number) => formatCurrency(value, currency) },
      ];

      const getCellBackgroundColor = (column: string, value: number, row: any) => {
        if (!heatmapOption || row.id === 'Total') return 'transparent';
        
        switch(column) {
          case 'cpc':
            return checkForAverage(toDecimal(row.avgCPC), value || 1);
          case 'ctr':
            return checkForAverage(toDecimal(row.avgCTR), value || 1);
          case 'costPerConversion':
            return checkForAverage(toDecimal(row.avgCostPerConversion), value || 1);
          case 'conversionRate':
            return checkForAverage(toDecimal(row.avgConversionRate), value || 1);
          case 'roas':
            return checkForAverage(toDecimal(row.avgROAS), value || 1);
          case 'valueByConversion':
            return checkForAverage(toDecimal(row.avgValueByConversion), value || 1);
          default:
            return 'transparent';
        }
      };
      
      return (
        <Card>
          <VuiBox p={3}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                {title || "Facebook Overview"}
              </VuiTypography>
              
              <Button 
                variant={heatmapOption ? "contained" : "outlined"}
                color="primary"
                onClick={switchHeatmap}
              >
                Heatmap
              </Button>
            </VuiBox>
            
            <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
              <Table stickyHeader sx={{ tableLayout: 'fixed', width: "1700px" }}>
                <TableHead sx={{ top: 0 }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ 
                          backgroundColor: '#1a2035',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          width: column.width,
                          minWidth: column.width
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {googleRows.map((row: any) => (
                    <TableRow 
                      key={row.id}
                      sx={{ 
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: row.id === 'Total' ? 'rgba(255, 255, 255, 0.05)' : 'transparent'
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell 
                            key={column.id} 
                            align={column.align ? (column.align as "left" | "center" | "right" | "inherit" | "justify") : 'right'}
                            sx={{
                              fontWeight: row.id === 'Total' ? 'bold' : 'normal',
                              backgroundColor: getCellBackgroundColor(column.id, value, row),
                              padding: '16px',
                              color: '#ffffff',
                              width: column.width,
                              minWidth: column.width
                            }}
                          >
                            {column.id === 'date' 
                              ? formatDate(value, timezone)
                              : (column.format && typeof value === 'number' 
                                ? column.format(value) 
                                : value)
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </VuiBox>
        </Card>
      );
};
