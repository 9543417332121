import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  title: string;
  facebookData: any;
  loading: boolean;
  currency: string;
}

export const CPAWidget = ({ facebookData, loading, title, currency = "USD" }: Props) => {
  let totalCPA = 0;
  
  if (facebookData && facebookData.graphData && facebookData.graphData.length > 0) {
    facebookData.graphData.forEach((row: any) => {
      totalCPA += row.costPerPurchase || 0;
    });
    totalCPA = totalCPA / facebookData.graphData.length;
  }
  
  totalCPA = Number(totalCPA.toFixed(2));

  return (
    <MiniStatisticsCard
      count={totalCPA.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      })}
      title={{ text: title, fontWeight: "bold" }}
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
