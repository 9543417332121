export enum UserRole {
    Owner = "Owner",
    Mentor = "Mentor",
    Viewer = "Viewer"
  }
  
  export const ADMIN_ROLES = [UserRole.Owner, UserRole.Mentor];
  
  export const hasAdminAccess = (userRole?: string): boolean => {
    if (!userRole) return false;
    return ADMIN_ROLES.includes(userRole as UserRole);
  }; 