import React from "react";
import { connect } from "react-redux";
import { setChannels } from "redux/actions";

import { Merchant } from "types/merchant";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";

const AttributionWindows = ["1d_view", "none"];

interface Props {
  selectedMerchant: Merchant;
  onChange?: (value: string) => void;
}

const FacebookAttributionWindowViewSelector: React.FC<Props> = ({
  selectedMerchant,
  onChange,
}) => {
  const handleChange = (newValue: { label: string; value: string }) => {
    if (onChange) {
      onChange(newValue.value);
    }
  };

  return (
    <VuiBox mb={3}>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          color="white"
        >
          View Window
        </VuiTypography>
      </VuiBox>
      <VuiSelect
        //@ts-ignore
        defaultValue={{
          label: humanReadableTitle(
            selectedMerchant.facebookAttributionWindowView || ""
          ),
          value: selectedMerchant.facebookAttributionWindowView || "",
        }}
        options={AttributionWindows.map((window) => ({
          label: humanReadableTitle(window),
          value: window,
        }))}
        label="Click Window"
        onChange={handleChange}
      />
    </VuiBox>
  );
};

const humanReadableTitle = (window: string) => {
  switch (window) {
    case "1d_view":
      return "1 Day View";
    case "none":
      return "None";
    default:
      return window;
  }
};

const mapStateToProps = ({ merchants }: { merchants: any }) => ({
  selectedMerchant: merchants.selectedMerchant,
});

const mapDispatchToProps = {
  setChannels,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookAttributionWindowViewSelector);
