import { type Merchant } from "types/merchant";
import { useSelector } from "react-redux";
import { useApi } from "hooks/useAPI";
import { useEffect, useState } from "react";
import { getLocalTime } from "utils/getLocalTime";
import {
  CustomersService,
  CustomerSummaryData,
} from "services/CustomersService";
import { AMERWidget } from "./CustomerWidets/AMERWidget";
import { TotalOrders } from "./CustomerWidets/TotalOrders";
import { CACWidget } from "./CustomerWidets/CACWidget";
import { FacebookROAS } from "./CustomerWidets/FacebookROASWidget";
import { NewCustomers } from "./CustomerWidets/NewCustomers";
import { ReturningCustomers } from "./CustomerWidets/ReturningCustomers";
import { ReturningCustomerOrders } from "./CustomerWidets/ReturningCustomerOrders";
import { NewCustomerRevenue } from "./CustomerWidets/NewCustomerRevenue";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import { GoogleROASWidget } from "./CustomerWidets/GoogleROASWidget";
export const CustomerWidgets = ({ facebookROAS }: { facebookROAS: number }) => {
  const { get } = useApi();

  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );
  const { startDate, endDate, compareOption } = useSelector(
    (state: any) => state.common
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [summary, setSummary] = useState<CustomerSummaryData | null>(null);

  useEffect(() => {
    const fetchSummary = async () => {
      const customersService = new CustomersService(get);

      const { startDateString, endDateString } = getLocalTime(
        startDate,
        endDate
      );

      setLoading(true);
      const result = await customersService.getSummary({
        merchantId: selectedMerchant.id,
        start: startDateString,
        end: endDateString,
        compareOption,
      });
      setLoading(false);
      setSummary(result);
    };

    if (selectedMerchant && startDate && endDate) {
      fetchSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant, startDate, endDate, compareOption]);

  return (
    <VuiBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <NewCustomers
              value={summary?.newCustomers.customerCount.value || 0}
              compareValue={
                summary?.newCustomers.customerCount.compareValue || 0
              }
              loading={loading}
            />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <NewCustomerRevenue
              value={summary?.newCustomers.totalAmount.value || 0}
              compareValue={summary?.newCustomers.totalAmount.compareValue || 0}
              loading={loading}
              currency={selectedMerchant.currency}
            />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <ReturningCustomers
              value={summary?.returningCustomers.customerCount.value || 0}
              compareValue={
                summary?.returningCustomers.customerCount.compareValue || 0
              }
              loading={loading}
            />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <ReturningCustomerOrders
              value={summary?.returningCustomers.orderCount.value || 0}
              compareValue={
                summary?.returningCustomers.orderCount.compareValue || 0
              }
              loading={loading}
            />
          </VuiBox>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={1.5}>
          <VuiBox mb={3}>
            <FacebookROAS value={facebookROAS} />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={1.5}>
          <VuiBox mb={3}>
            <GoogleROASWidget />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <AMERWidget
              value={summary?.aMER.value || 0}
              compareValue={summary?.aMER.compareValue || 0}
              loading={loading}
            />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <TotalOrders
              value={summary?.totalOrders.value || 0}
              compareValue={summary?.totalOrders.compareValue || 0}
              loading={loading}
            />
          </VuiBox>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <VuiBox mb={3}>
            <CACWidget
              value={summary?.CAC.value || 0}
              compareValue={summary?.CAC.compareValue || 0}
              loading={loading}
              currency={selectedMerchant.currency}
            />
          </VuiBox>
        </Grid>
      </Grid>
    </VuiBox>
  );
};
