import { Button, Card, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Box } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useState } from "react";
import moment from "moment-timezone";


interface Props {
  title: string;
  facebookData: any;
  loading: boolean;
  timezone?: string;
  currency?: string;
}

const toDecimal = (value: any) => {
  if (!value && value !== 0) return 0;
  return Number(parseFloat(value).toFixed(2));
};

const checkForAverage = (avg: number, current: number) => {
  if (current > avg) {
    return "rgba(0, 255, 0, 0.2) !important";
  } else if (current < avg) {
    return "rgba(255, 0, 0, 0.2) !important";
  }
  return "transparent !important";
};

const formatDate = (dateString: string, timezone?: string) => {
  if (dateString === 'Total') return dateString;
  
  try {
    if (timezone) {
      return moment(dateString).tz(timezone).format('MMM DD, YYYY');
    }
    return moment(dateString).format('MMM DD, YYYY');
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
};

const formatCurrency = (value: number, currencyCode: string = 'USD'): string => {
  return toDecimal(value).toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
  });
};

export const FacebookOverview = ({ facebookData, loading, title, timezone, currency }: Props) => {
    const [heatmapOption, setHeatmapOption] = useState(false);

    if (!facebookData || !facebookData.graphData || !Array.isArray(facebookData.graphData)) {
        return (
            <Card>
                <VuiBox p={3}>
                    <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                        <VuiTypography variant="lg" color="white" fontWeight="bold">
                            {title || "Facebook Overview"}
                        </VuiTypography>
                    </VuiBox>
                    <VuiBox display="flex" justifyContent="center" alignItems="center" height="200px">
                        <VuiTypography variant="button" color="text">
                            {loading ? "Loading data..." : "No Facebook data available"}
                        </VuiTypography>
                    </VuiBox>
                </VuiBox>
            </Card>
        );
    }

    let facebookRows = facebookData.graphData.map((row: any) => {
        return {
          id: row._id,
          spend: row.totalSpend,
          date: row._id,
          impressions: row.impressions,
          purchaseROAS: row.purchaseRoas,
          purchases: row.purchases,
          costPerPurchase: row.costPerPurchase,
          reach: row.reach,
          frequency: row.frequency,
          outboundClicks: row.outboundClicks,
          costPerOutboundClick: row.costPerOutboundClick,
          outboundCTR: row.outboundClicksCtr,
          cpm: row.cpm,
          addToCarts: row.addToCarts,
          costPerATC: row.costPerATC,
          checkoutsInitiated: row.checkoutInitiated,
          purchaseConversionValue:
            parseFloat(row.purchaseRoas) * parseFloat(row.totalSpend),
          aov:
            (parseFloat(row.purchaseRoas || 0) * parseFloat(row.totalSpend || 0)) /
            (row.purchases || 1),
          cvr: (row.purchases / (row.outboundClicks || 1)) * 100,
          atcP: row.addToCarts / (row.outboundClicks || 1),
        };
      });
      facebookRows = facebookRows.sort((a: any, b: any) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      });
    
      const totalObject = {
        id: "Total",
        spend: 0,
        date: "Total",
        impressions: 0,
        purchaseROAS: 0,
        purchases: 0,
        costPerPurchase: 0,
        reach: 0,
        frequency: 0,
        outboundClicks: 0,
        costPerOutboundClick: 0,
        outboundCTR: 0,
        cpm: 0,
        addToCarts: 0,
        costPerATC: 0,
        checkoutsInitiated: 0,
        purchaseConversionValue: 0,
        aov: 0,
        cvr: 0,
        atcP: 0,
      };
      facebookData.graphData.forEach((row: any) => {
        totalObject.spend += row.totalSpend;
        totalObject.impressions += row.impressions;
        totalObject.purchaseROAS += row.purchaseRoas;
        totalObject.purchases += row.purchases;
        totalObject.costPerPurchase += row.costPerPurchase;
        totalObject.reach += row.reach;
        totalObject.frequency += row.frequency;
        totalObject.outboundClicks += row.outboundClicks;
        totalObject.costPerOutboundClick += row.costPerOutboundClick;
        totalObject.outboundCTR += row.outboundClicksCtr;
        totalObject.cpm += row.cpm;
        totalObject.addToCarts += row.addToCarts;
        totalObject.costPerATC += row.costPerATC;
        totalObject.checkoutsInitiated += row.checkoutInitiated;
        totalObject.purchaseConversionValue +=
          parseFloat(row.purchaseRoas) * parseFloat(row.totalSpend);
        totalObject.aov +=
          (parseFloat(row.purchaseRoas) * parseFloat(row.totalSpend)) /
          row.purchases;
        totalObject.cvr += (row.purchases / row.outboundClicks) * 100;
        totalObject.atcP += row.addToCarts / row.outboundClicks;
      });
      totalObject.purchaseConversionValue =
        totalObject.purchaseConversionValue / facebookData.graphData.length;
      totalObject.purchaseROAS =
        totalObject.purchaseROAS / facebookData.graphData.length;
      totalObject.aov = totalObject.aov / facebookData.graphData.length;
      totalObject.cvr = totalObject.cvr / facebookData.graphData.length;
      totalObject.costPerPurchase =
        totalObject.costPerPurchase / facebookData.graphData.length;
      totalObject.frequency =
        totalObject.frequency / facebookData.graphData.length;
      totalObject.outboundCTR =
        totalObject.outboundCTR / facebookData.graphData.length;
      totalObject.costPerOutboundClick =
        totalObject.costPerOutboundClick / facebookData.graphData.length;
      totalObject.cpm = totalObject.cpm / facebookData.graphData.length;
      totalObject.atcP = totalObject.atcP / facebookData.graphData.length;
      totalObject.costPerATC =
        totalObject.costPerATC / facebookData.graphData.length;
    
      if (facebookRows.length > 1) {
        facebookRows.push(totalObject);
      }

      facebookRows = facebookRows.map((v: any) => ({
        ...v,
        totalSpend: totalObject.spend,
        avgPCV: totalObject.purchaseConversionValue,
        avgPROAS: totalObject.purchaseROAS,
        avgAOV: totalObject.aov,
        avgCVR: totalObject.cvr,
        totalPurchases: totalObject.purchases,
        avgCPP: totalObject.costPerPurchase,
        totalImpressions: totalObject.impressions,
        totalReach: totalObject.reach,
        avgFrequency: totalObject.frequency,
        totalOutboundClicks: totalObject.outboundClicks,
        avgCPOC: totalObject.costPerOutboundClick,
        avgOCTR: totalObject.outboundCTR,
        avgCPM: totalObject.cpm,
        avgAtcp: totalObject.atcP,
        totalAddToCarts: totalObject.addToCarts,
        avgCPATC: totalObject.costPerATC,
        totalCI: totalObject.checkoutsInitiated
      }));

      const switchHeatmap = () => setHeatmapOption((val) => !val);

      const columns = [
        { id: 'date', label: 'Date', width: '100px', align: 'left' },
        { id: 'spend', label: 'Amount Spent', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'purchaseConversionValue', label: 'Purchase Conversion Value', width: '180px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'purchaseROAS', label: 'Purchase ROAS', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'aov', label: 'AOV', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'cvr', label: 'CVR', width: '140px', format: (value: number) => `${toDecimal(value).toLocaleString()}%` },
        { id: 'purchases', label: 'Purchases', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'costPerPurchase', label: 'Cost Per Purchase', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'impressions', label: 'Impressions', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'reach', label: 'Reach', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'frequency', label: 'Frequency', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'outboundClicks', label: 'Outbound Clicks', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'costPerOutboundClick', label: 'Cost Per Outbound Click', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'outboundCTR', label: 'Outbound CTR', width: '140px', format: (value: number) => `${toDecimal(value).toLocaleString()}%` },
        { id: 'cpm', label: 'CPM', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'atcP', label: 'ATC %', width: '140px', format: (value: number) => `${toDecimal(value).toLocaleString()}%` },
        { id: 'addToCarts', label: 'Add To Carts', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() },
        { id: 'costPerATC', label: 'Cost Per ATC', width: '140px', format: (value: number) => formatCurrency(value, currency) },
        { id: 'checkoutsInitiated', label: 'Checkouts Initiated', width: '140px', format: (value: number) => toDecimal(value).toLocaleString() }
      ];

      const getCellBackgroundColor = (column: string, value: number, row: any) => {
        if (!heatmapOption || row.id === 'Total') return 'transparent';
        
        switch(column) {
          case 'purchaseConversionValue':
            return checkForAverage(toDecimal(row.avgPCV), value || 1);
          case 'purchaseROAS':
            return checkForAverage(toDecimal(row.avgPROAS), value || 1);
          case 'aov':
            return checkForAverage(toDecimal(row.avgAOV), value || 1);
          case 'cvr':
            return checkForAverage(toDecimal(row.avgCVR), value || 1);
          case 'costPerPurchase':
            return checkForAverage(toDecimal(row.avgCPP), value || 1);
          case 'frequency':
            return checkForAverage(toDecimal(row.avgFrequency), value || 1);
          case 'costPerOutboundClick':
            return checkForAverage(toDecimal(row.avgCPOC), value || 1);
          case 'outboundCTR':
            return checkForAverage(toDecimal(row.avgOCTR), value || 1);
          case 'cpm':
            return checkForAverage(toDecimal(row.avgCPM), value || 0);
          case 'atcP':
            return checkForAverage(toDecimal(row.avgAtcp), value || 1);
          case 'costPerATC':
            return checkForAverage(toDecimal(row.avgCPATC), value || 1);
          default:
            return 'transparent';
        }
      };
      
      return (
        <Card>
          <VuiBox p={3}>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                {title || "Facebook Overview"}
              </VuiTypography>
              
              <Button 
                variant={heatmapOption ? "contained" : "outlined"}
                color="primary"
                onClick={switchHeatmap}
              >
                Heatmap
              </Button>
            </VuiBox>
            
            <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
              <Table stickyHeader sx={{ tableLayout: 'fixed', width: "2660px" }}>
                <TableHead sx={{ top: 0 }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ 
                          backgroundColor: '#1a2035',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          width: column.width,
                          minWidth: column.width
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {facebookRows.map((row: any) => (
                    <TableRow 
                      key={row.id}
                      sx={{ 
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: row.id === 'Total' ? 'rgba(255, 255, 255, 0.05)' : 'transparent'
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell 
                            key={column.id} 
                            align={column.align ? (column.align as "left" | "center" | "right" | "inherit" | "justify") : 'right'}
                            sx={{
                              fontWeight: row.id === 'Total' ? 'bold' : 'normal',
                              backgroundColor: getCellBackgroundColor(column.id, value, row),
                              padding: '16px',
                              color: '#ffffff',
                              width: column.width,
                              minWidth: column.width
                            }}
                          >
                            {column.id === 'date' 
                              ? formatDate(value, timezone)
                              : (column.format && typeof value === 'number' 
                                ? column.format(value) 
                                : value)
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </VuiBox>
        </Card>
      );
};
