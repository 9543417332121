import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { Grid, Stack } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

const chartOptions = {
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: "70%",
      barHeight: "100%",
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        enabled: false,
      },
    },
  },
  fill: {
    colors: ["#34E2AB", "#3379EB"],
  },
  stroke: {
    width: [1, 1, 5],
    colors: ["#fff", "#fff", undefined]
  },
  tooltip: {
    theme: "dark",
  },
  legend: {
    labels: {
      colors: "#fff",
    },
  },
  xaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "15px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "15px",
      },
    },
  },
};

const monthNames = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const GoogleChart = ({ googleAdsData }) => {
  const [activeBtn, setactiveBtn] = useState({});
  const [options, setOptions] = useState({});
  const [seriesBar, setSeriesBar] = useState([]);
  const { plotOptions, fill, stroke } = chartOptions;
  const graphData = googleAdsData.graphData;
  let costArray = graphData.map((row) => row.cost);
  let conversionValueArray = graphData.map((row) => row.conversionValue);
  let roasArray = graphData.map((row) => row.roas);
  let valueConvArray = graphData.map((row) => row.valueByConversion);
  let costPerConversionArray = graphData.map((row) => row.costPerConversion);
  let cpcArray = graphData.map((row) => row.cpc);
  let ctrArray = graphData.map((row) => row.ctr);
  let convRateArray = graphData.map((row) => row.conversionRate);

  const formatDateCategories = (value) => {
    if (value._id.toString().includes("-")) {
      return `${monthNames[value._id.split("-")[1]]} ${
        value._id.split("-")[2]
      }`;
    } else {
      if (value._id.toString().length > 1) {
        return `${monthNames[value._id.toString()]}`;
      } else {
        return `${monthNames["0" + value._id.toString()]}`;
      }
    }
  };

  const renderGraph = (type) => {
    if (type === "cac") {
      setOptions({
        xaxis: {
          categories: graphData.map(formatDateCategories),
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return "$" + value;
              },
            },
            seriesName: "Cost Per Conversion",
            show: true,
          },
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
            },
            seriesName: "Value/Conve",
            show: false,
          },
          {
            opposite: true,
            seriesName: "Value/Conve",
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return Math.round(value);
              },
            },
          },
        ],
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        opposite: true,
        plotOptions,
        fill,
        stroke,
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      });
      setSeriesBar([
        {
          data: costPerConversionArray,
          name: "Cost/Conv",
          type: "bar",
          color: "#3498db",
        },
        {
          data: conversionValueArray,
          name: "Conv Value",
          type: "bar",
          color: "#D980FA",
        },
        {
          data: valueConvArray,
          name: "Value/Conv",
          type: "line",
          color: "#009432",
        },
      ]);
      setactiveBtn({
        cac: true,
        ccm: false,
        spcv: false,
      });
    }
    if (type === "spcv") {
      setOptions({
        xaxis: {
          categories: graphData.map(formatDateCategories),
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return "$" + value;
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        opposite: true,
        plotOptions,
        fill,
        stroke,
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      });
      setSeriesBar([
        { data: costArray, name: "Cost", type: "bar", color: "#1abc9c" },
        {
          data: costPerConversionArray,
          name: "Cost Per Conversion",
          type: "bar",
          color: "#FFC312",
        },
      ]);
      setactiveBtn({
        cac: false,
        ccm: false,
        spcv: true,
      });
    }
    if (type === "ccm") {
      setOptions({
        xaxis: {
          categories: graphData.map(formatDateCategories),
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return "$" + value.toFixed(2);
              },
            },
            seriesName: "CPC",
            opposite: true,
            show: true,
          },
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return value + "%";
              },
            },
            seriesName: "Conv Rate",
            show: false,
          },
          {
            opposite: false,
            seriesName: "Conv Rate",
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return value + "%";
              },
            },
          },
          {
            seriesName: "Conv Rate",
            show: false,
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return value;
              },
            },
          },
        ],
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        opposite: true,
        plotOptions,
        fill,
        stroke: {
          ...stroke,
          width: [5, 1, 1, 1],
        },
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      });
      setSeriesBar([
        { data: cpcArray, name: "CPC", type: "line", color: "#5352ed" },
        { data: ctrArray, name: "CTR", type: "bar", color: "#ff4757" },
        { data: roasArray, name: "ROAS", type: "bar", color: "#ffa502" },
        {
          data: convRateArray,
          name: "Conv Rate",
          type: "bar",
          color: "#22a6b3",
        },
      ]);
      setactiveBtn({
        cac: false,
        ccm: true,
        spcv: false,
      });
    }
  };

  useEffect(() => {
    setOptions({
      xaxis: {
        categories: graphData.map(formatDateCategories),
        labels: {
          style: {
            fontSize: "15px",
            colors: "#fff",
          },
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return "$" + value;
            },
          },
          seriesName: "Cost Per Conversion",
          show: true,
        },
        {
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
          seriesName: "Value/Conve",
          show: false,
        },
        {
          opposite: true,
          seriesName: "Value/Conve",
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return "$" + Math.round(value);
            },
          },
        },
      ],
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: false,
      },
      opposite: true,
      plotOptions,
      fill,
      stroke,
      legend: {
        labels: {
          colors: "#fff",
        },
      },
    });
    setSeriesBar([
      {
        data: costPerConversionArray,
        name: "Cost/Conv",
        type: "bar",
        color: "#3498db",
      },
      {
        data: conversionValueArray,
        name: "Conv Value",
        type: "bar",
        color: "#D980FA",
      },
      {
        data: valueConvArray,
        name: "Value/Conv",
        type: "line",
        color: "#009432",
      },
    ]);
    setactiveBtn({
      cac: true,
      ccm: false,
      spcv: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VuiBox>
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ 
          display: "flex", 
          flexDirection: "row", 
          justifyContent: "flex-start",
          mb: 2
        }}
      >
        <VuiButton
          onClick={() => renderGraph("cac")}
          variant={activeBtn.cac ? "contained" : "outlined"}
          color="primary"
          sx={{ 
            borderRadius: "0.625rem",
            backgroundColor: activeBtn.cac ? "#536dfe" : "transparent",
            "&:hover": {
              backgroundColor: activeBtn.cac ? "#536dfe" : "rgba(83, 109, 254, 0.1)"
            }
          }}
        >
          <VuiTypography variant="button" color="white" fontWeight="regular">
            Cost/Conv x Conv Value x Value/Conv
          </VuiTypography>
        </VuiButton>
        
        <VuiButton
          onClick={() => renderGraph("ccm")}
          variant={activeBtn.ccm ? "contained" : "outlined"}
          color="primary"
          sx={{ 
            borderRadius: "0.625rem",
            backgroundColor: activeBtn.ccm ? "#536dfe" : "transparent",
            "&:hover": {
              backgroundColor: activeBtn.ccm ? "#536dfe" : "rgba(83, 109, 254, 0.1)"
            }
          }}
        >
          <VuiTypography variant="button" color="white" fontWeight="regular">
            CPC x CTR x ROAS x Conv Rate
          </VuiTypography>
        </VuiButton>
        
        <VuiButton
          onClick={() => renderGraph("spcv")}
          variant={activeBtn.spcv ? "contained" : "outlined"}
          color="primary"
          sx={{ 
            borderRadius: "0.625rem",
            backgroundColor: activeBtn.spcv ? "#536dfe" : "transparent",
            "&:hover": {
              backgroundColor: activeBtn.spcv ? "#536dfe" : "rgba(83, 109, 254, 0.1)"
            }
          }}
        >
          <VuiTypography variant="button" color="white" fontWeight="regular">
            Cost x Cost Per Conversion
          </VuiTypography>
        </VuiButton>
      </Stack>
      
      <VuiBox
        sx={{
          height: "500px",
          width: "100%",
          background: "rgba(0, 0, 0, 0.2)",
          borderRadius: "20px",
          p: 2
        }}
      >
        <Chart
          title={"Google Overview"}
          options={options}
          series={seriesBar}
          type={"bar"}
          key={"bar"}
          width="100%"
          height="100%"
        />
      </VuiBox>
    </VuiBox>
  );
};

const mapStateToProps = ({ googleData }) => {
  const { data: googleAdsData } = googleData;
  return {
    googleAdsData,
  };
};

export default connect(mapStateToProps, null)(GoogleChart);
