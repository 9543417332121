import React from "react";
import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { useFacebookTokenExpiration } from "hooks/useFacebookTokenExpiration";
import { useHistory } from "react-router-dom";

interface FacebookTokenExpirationAlertProps {
  showProgressBar?: boolean;
}

const FacebookTokenExpirationAlert: React.FC<FacebookTokenExpirationAlertProps> = () => {
  const { isExpired, isNearExpiration } = useFacebookTokenExpiration();
  const history = useHistory();
  
  if (!isExpired && !isNearExpiration) {
    return null;
  }

  const navigateToStoreDetails = () => {
    history.push("/app/settings/store-connections");
  };

  return (
    <VuiBox width="100%" mb={0} mt={0}>
      <Card sx={{
        borderLeft: isExpired ? '4px solid #ff4d4f' : '4px solid #faad14',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.14), 0 7px 10px -5px rgba(0,0,0,0.4)'
      }}>
        <VuiBox p={0}>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiBox>
              <VuiTypography variant="button" color={isExpired ? "error" : "warning"} fontWeight="bold">
                {isExpired 
                  ? "Facebook Access Token Expired" 
                  : "Facebook Access Token Expiring Soon"}
              </VuiTypography>
              <VuiTypography variant="caption" color="text" fontWeight="regular" display="block" mt={0.5}>
                {isExpired 
                  ? "Your Facebook integration features may not work properly. Please refresh your token." 
                  : "Please refresh your token to keep your Facebook data up to date"}
              </VuiTypography>
            </VuiBox>
            {/* @ts-ignore */}
            <VuiButton 
              variant="contained" 
              color={isExpired ? "error" : "warning"}
              onClick={navigateToStoreDetails}
              size="small"
            >
              Refresh Token
            </VuiButton>
          </VuiBox>
        </VuiBox>
      </Card>
    </VuiBox>
  );
};

export default FacebookTokenExpirationAlert; 