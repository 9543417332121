import { useDispatch, useSelector } from "react-redux";

import { Merchant } from "types/merchant";
import { useApi } from "hooks/useAPI";
import { useEffect } from "react";
import {
  setMerchants,
  selectedMerchant as setSelectedMerchant,
} from "redux/actions";
import VuiSelect from "components/VuiSelect";

const MerchantSelector = () => {
  const dispatch = useDispatch();
  const { get, loading } = useApi();
  const merchants = useSelector((state: any) => state.merchants.merchant);
  const selectedMer = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  useEffect(() => {
    const fetchMerchants = async () => {
      const data = await get<Merchant[]>("merchant/getUserMerchants");
      dispatch(setMerchants(data));
      if (data.length > 0 && !selectedMer.id) {
        dispatch(setSelectedMerchant(data[0]));
      }
    };

    if (!loading) {
      fetchMerchants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSelect = async ({ value }: { value: string }) => {
    const selectedMerchant = merchants.find(
      (merchant: Merchant) => merchant.id === value
    );
    if (selectedMerchant) {
      await dispatch(setSelectedMerchant(selectedMerchant));
    }
  };

  if (merchants.length === 0) {
    return null;
  }

  return (
    <VuiSelect
      // @ts-ignore
      defaultValue={{ value: selectedMer.id, label: selectedMer.name }}
      options={merchants.map((item: Merchant) => ({
        value: item.id,
        label: item.name,
      }))}
      onChange={onChangeSelect}
    />
  );
};

export default MerchantSelector;
