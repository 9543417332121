import { BaseWidget } from "./BaseWidget";

interface Props {
  value: number;
  compareValue: number;
  loading: boolean;
  currency: string;
}

export const CACWidget = ({
  value,
  compareValue,
  loading,
  currency,
}: Props) => {
  return (
    <BaseWidget
      title="CAC"
      value={value}
      compareValue={compareValue}
      loading={loading}
      downIsGood
      subtitle="Total spend / New customers"
      valueFormatter={(value) =>
        value.toLocaleString("en-US", {
          style: "currency",
          currency: currency || "USD",
        })
      }
    />
  );
};
