import { useState } from "react";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "react-google-login";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  List,
  ListItem,
  ListItemText,
  Radio,
} from "@mui/material";
import { useSnackbar } from "notistack";
import VuiButton from "components/VuiButton";
import { env } from "configs/EnvironmentConfig";
import { useApi } from "hooks/useAPI";
import { selectedMerchant as setSelectedMerchant, setMerchants } from "redux/actions";
import AppService from "../../services/AppService";
import MerchantService from "../../services/MerchantService";

const { GOOGLE_CLIENT_ID, GOOGLE_SCOPES } = env;

const GoogleConnect = ({ merchant }) => {
  const { getToken } = useApi();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [accountIds, setAccountIds] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);

  const responseGoogle = async (response) => {
    if (response?.code) {
      try {
        setLoading(true);
        const token = getToken ? await getToken() : "";
        const getCustomers = await AppService.googleInstall(
          { code: response.code },
          token
        );
        const { customers, refreshToken } = getCustomers;
        const accounts = customers?.map(
          (customer) => customer?.split("customers/")[1]
        );
        setRefreshToken(refreshToken);
        setAccountIds(accounts);
        setShowModal(true);
      } catch (e) {
        enqueueSnackbar("Failed to connect Google account", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      enqueueSnackbar("Google Connection Failed", { variant: "error" });
    }
  };

  const addGoogleAccountId = async () => {
    const data = {
      merchantId: merchant?.id,
      googleAccountId: accountId,
      googleRefreshToken: refreshToken,
    };
    const token = getToken ? await getToken() : "";
    const apiResponse = await AppService.googleInstall(data, token);
    dispatch(setSelectedMerchant({ ...apiResponse, roles: merchant.roles }));

    const updatedMerchants = await MerchantService.getMerchant(token);
    if (updatedMerchants && Array.isArray(updatedMerchants)) {
      dispatch(setMerchants(updatedMerchants));

      const updatedSelectedMerchant = updatedMerchants.find(m => m.id === apiResponse.id);
      if (updatedSelectedMerchant) {
        dispatch(setSelectedMerchant({ ...updatedSelectedMerchant, roles: merchant.roles }));
      }
    }

    enqueueSnackbar("Google account added!", { variant: "success" });
  };

  const onSearch = (query) => {
    if (query !== "") {
      const filter = accountIds.filter((item) => item?.includes(query));
      setSearch(filter);
    } else {
      setSearch([]);
    }
  };

  return (
    <>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Sign in with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        scope={GOOGLE_SCOPES}
        responseType="code"
        accessType="offline"
        cookiePolicy="single_host_origin"
        prompt="consent"
        redirectUri="postmessage"
        render={(renderProps) => (
          <VuiButton
            loading={loading}
            variant="contained"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Connect
          </VuiButton>
        )}
      />

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            color: "white",
          },
        }}
      >
        <DialogTitle sx={{ color: "white !important" }}>
          Select a Google Account
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              placeholder="Search accounts"
              onChange={(e) => onSearch(e.target.value)}
              sx={{
                input: { color: "white" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                  "&:hover fieldset": { borderColor: "white" },
                },
              }}
            />
            <List sx={{ width: "100%" }}>
              {(search.length > 0 ? search : accountIds).map((id) => (
                <ListItem
                  key={id}
                  onClick={() => setAccountId(id)}
                  sx={{
                    cursor: "pointer",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                    "&:last-child": { borderBottom: "none" },
                  }}
                >
                  <Radio
                    checked={accountId === id}
                    onChange={() => setAccountId(id)}
                    sx={{
                      color: "white !important",
                    }}
                  />
                  <ListItemText
                    primary={id}
                    sx={{ color: "white !important" }}
                  />
                </ListItem>
              ))}
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <VuiButton variant="outlined" onClick={() => setShowModal(false)}>
            Cancel
          </VuiButton>
          <VuiButton
            variant="contained"
            disabled={!accountId}
            onClick={addGoogleAccountId}
          >
            Update
          </VuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GoogleConnect;
