import {
  autoUpdate,
  flip,
  FloatingPortal,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { useApi } from "hooks/useAPI";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Merchant } from "types/merchant";
import { getLocalTime } from "utils/getLocalTime";
import { BaseWidget } from "./BaseWidget";
import { BsCurrencyDollar } from "react-icons/bs";
import { Card, Stack } from "@mui/material";
import VuiTypography from "components/VuiTypography";

interface ProfitResponse {
  totalProfit: {
    value: number;
    compareValue: number;
  },
  totalContributionMargin: {
    value: number;
    compareValue: number;
  },
  totalOpex: {
    value: number;
    compareValue: number;
  }
}

export const ProfitWidget = () => {
  const { get } = useApi();
  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const { startDate, endDate } = useSelector((state: any) => state.common);
  const [loading, setLoading] = useState(false);
  const [profitValue, setProfitValue] = useState(0);
  const [profitCompareValue, setProfitCompareValue] = useState(0);
  const [contributionMarginValue, setContributionMarginValue] = useState(0);
  const [opexValue, setOpexValue] = useState(0);
  const isFetchingRef = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom",
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
  ]);

  useEffect(() => {
    if (!selectedMerchant?.id || !startDate || !endDate || isFetchingRef.current) return;
    
    const { startDateString, endDateString } = getLocalTime(startDate, endDate);
    setLoading(true);
    isFetchingRef.current = true;
    
    get<ProfitResponse>(`sales/profit?merchantId=${selectedMerchant.id}&startDate=${startDateString}&endDate=${endDateString}`)
      .then(response => {
        setProfitValue(response.totalProfit.value);
        setProfitCompareValue(response.totalProfit.compareValue);
        setOpexValue(response.totalOpex.value);
        setContributionMarginValue(response.totalContributionMargin.value);
      })
      .catch(error => {
        console.error("Error fetching profit data:", error);
        setProfitValue(0);
        setProfitCompareValue(0);
        setOpexValue(0);
        setContributionMarginValue(0);
      })
      .finally(() => {
        setLoading(false);
        isFetchingRef.current = false;
      });
      
  }, [selectedMerchant, startDate, endDate, get]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <BaseWidget
          title="Profit"
          value={profitValue}
          compareValue={profitCompareValue}
          loading={loading}
          currency={selectedMerchant.currency || "USD"}
          downIsGood={false}
          subtitle="Net profit after expenses"
          showHoverIndicator
          icon={<BsCurrencyDollar color="white" />}
        />
      </div>
      <FloatingPortal>
        {isOpen && !loading && (
          <Card
            ref={refs.setFloating}
            {...getFloatingProps()}
            role="tooltip"
            style={{
              ...floatingStyles,
              width: 200,
              opacity: isOpen ? 1 : 0,
              transition: "opacity 0.2s",
              padding: "16px",
            }}
          >
            <Stack spacing={2}>
              <VuiTypography
                variant="body2"
                color="white"
                opacity={0.7}
                textTransform="capitalize"
                fontWeight="medium"
              >
                Profit Breakdown
              </VuiTypography>
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="space-between">
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    Contribution Margin:
                  </VuiTypography>
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    {contributionMarginValue?.toLocaleString("en-US", {
                      style: "currency",
                      currency: selectedMerchant.currency || "USD",
                    })}
                  </VuiTypography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    OPEX:
                  </VuiTypography>
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    {opexValue?.toLocaleString("en-US", {
                      style: "currency",
                      currency: selectedMerchant.currency || "USD",
                    })}
                  </VuiTypography>
                </Stack>
              </Stack>
              <VuiTypography
                variant="caption"
                color="white"
                fontWeight="regular"
              >
                Set monthly OPEX in shopify settings.
              </VuiTypography>
            </Stack>
          </Card>
        )}
      </FloatingPortal>
    </>
  );
};
