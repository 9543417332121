import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { useSelector } from "react-redux";
import { Grid, Stack } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

const chartOptions = {
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: "70%",
      barHeight: "100%",
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        enabled: false,
      },
    },
  },
  fill: {
    colors: ["#34E2AB", "#3379EB"],
  },
  stroke: {
    width: [1, 1, 5],
    colors: ["#fff", "#fff", undefined]
  },
  tooltip: {
    theme: "dark",
  },
  legend: {
    labels: {
      colors: "#fff",
    },
  },
  xaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "15px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "15px",
      },
    },
  },
};

const FBOChart = ({ FBOGraphData }) => {
  const filteredOrders = useSelector(
    (state) => state.shopifyOrders.filteredOrders
  );
  
  FBOGraphData = FBOGraphData.sort((a, b) => {
    return moment(a._id).valueOf() - moment(b._id).valueOf();
  });

  const [options, setOptions] = useState({});
  const [activeBtn, setactiveBtn] = useState({});
  const [seriesBar, setSeriesBar] = useState([]);
  const { plotOptions, fill, stroke } = chartOptions;

  let spend = [];
  let costPerPurchase = [];
  let purchaseConversionValue = [];
  let aov = [];
  let cvr = [];
  let cac = [];
  let mer = [];
  let revWithRefunds = [];
  let rev = filteredOrders.graphData;
  spend = FBOGraphData?.map((row) => row?.totalSpend?.toFixed(2));
  costPerPurchase = FBOGraphData?.map((row) =>
    row?.costPerPurchase?.toFixed(2)
  );
  purchaseConversionValue = FBOGraphData.map((row) =>
    (parseFloat(row.purchaseRoas) * parseFloat(row.totalSpend)).toFixed(2)
  );
  aov = FBOGraphData.map((row) =>
    (
      (parseFloat(row.purchaseRoas) * parseFloat(row.totalSpend)) /
      row.purchases
    ).toFixed(2)
  );
  cvr = FBOGraphData.map((row) =>
    ((row.purchases / row.outboundClicks) * 100).toFixed(2)
  );

  // CAC
  FBOGraphData?.forEach((date) => {
    filteredOrders?.newCustomersGraphData?.forEach((row) => {
      if (date._id === row._id) {
        cac.push((date.totalSpend / row.count).toFixed(2));
      }
    });
  });
  filteredOrders?.graphData?.forEach((date) => {
    filteredOrders?.graphDataRefunds?.forEach((row) => {
      if (date._id === row._id) {
        revWithRefunds.push({
          _id: row._id,
          totalAmount: date.totalAmount - row.totalAmount,
        });
      }
    });
  });

  let revCombined = rev?.concat(revWithRefunds);

  revCombined = [
    ...new Map(revCombined?.map((item) => [item["_id"], item])).values(),
  ];
  // MER
  FBOGraphData.forEach((date) => {
    revCombined.forEach((row) => {
      if (date._id === row._id) {
        mer.push((row.totalAmount / date.totalSpend).toFixed(2));
      }
    });
  });

  const renderGraph = (type) => {
    if (type === "cac") {
      setOptions({
        xaxis: {
          categories: FBOGraphData.map((val) => val._id),
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return "$" + value;
              },
            },
            seriesName: "AOV",
            show: false,
          },
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return "$" + value;
              },
            },
            seriesName: "AOV",
          },
          {
            opposite: true,
            seriesName: "CVR",
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return value + "%";
              },
            },
          },
        ],
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        opposite: true,
        plotOptions,
        fill,
        stroke,
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      });
      setSeriesBar([
        {
          data: costPerPurchase,
          name: "Cost per purchase",
          type: "bar",
          color: "#3498db",
        },
        { data: aov, name: "AOV", type: "bar", color: "#D980FA" },
        { data: cvr, name: "CVR", type: "line", color: "#009432" },
      ]);
      setactiveBtn({
        cac: true,
        ccm: false,
        spcv: false,
      });
    }
    if (type === "spcv") {
      setOptions({
        xaxis: {
          categories: FBOGraphData.map((val) => val._id),
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return "$" + value;
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        opposite: true,
        plotOptions,
        fill,
        stroke,
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      });
      setSeriesBar([
        { data: spend, name: "Spend", type: "bar", color: "#1abc9c" },
        {
          data: purchaseConversionValue,
          name: "Purchase Conversion Value",
          type: "bar",
          color: "#FFC312",
        },
      ]);
      setactiveBtn({
        cac: false,
        ccm: false,
        spcv: true,
      });
    }
    if (type === "ccm") {
      setOptions({
        xaxis: {
          categories: FBOGraphData.map((val) => val._id),
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return "$" + value;
              },
            },
            seriesName: "CPA",
            show: false,
          },
          {
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return "$" + value;
              },
            },
            seriesName: "CPA",
          },
          {
            opposite: true,
            seriesName: "MER",
            labels: {
              style: {
                fontSize: "15px",
                colors: "#fff",
              },
              formatter: function (value) {
                return value;
              },
            },
          },
        ],
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        opposite: true,
        plotOptions,
        fill,
        stroke,
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      });
      setSeriesBar([
        { data: costPerPurchase, name: "CPA", type: "bar", color: "#5352ed" },
        { data: cac, name: "CAC", type: "bar", color: "#ff4757" },
        { data: mer, name: "MER", type: "line", color: "#ffa502" },
      ]);
      setactiveBtn({
        cac: false,
        ccm: true,
        spcv: false,
      });
    }
  };
  useEffect(() => {
    setOptions({
      xaxis: {
        categories: FBOGraphData.map((val) => val._id),
        labels: {
          style: {
            fontSize: "15px",
            colors: "#fff",
          },
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return "$" + value;
            },
          },
          seriesName: "AOV",
          show: false,
        },
        {
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return "$" + value;
            },
          },
          seriesName: "AOV",
        },
        {
          opposite: true,
          seriesName: "CVR",
          labels: {
            style: {
              fontSize: "15px",
              colors: "#fff",
            },
            formatter: function (value) {
              return value + "%";
            },
          },
        },
      ],
      markers: {
        size: 4,
      },
      dataLabels: {
        enabled: false,
      },
      opposite: true,
      plotOptions,
      fill,
      stroke,
      legend: {
        labels: {
          colors: "#fff",
        },
      },
    });
    setSeriesBar([
      {
        data: costPerPurchase,
        name: "Cost per purchase",
        type: "bar",
        color: "#3498db",
      },
      { data: aov, name: "AOV", type: "bar", color: "#D980FA" },
      { data: cvr, name: "CVR", type: "line", color: "#009432" },
    ]);
    setactiveBtn({
      cac: true,
      ccm: false,
      spcv: false,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <VuiBox>
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ 
          display: "flex", 
          flexDirection: "row", 
          justifyContent: "flex-start",
          mb: 2
        }}
      >
        <VuiButton
          onClick={() => renderGraph("cac")}
          variant={activeBtn.cac ? "contained" : "outlined"}
          color="primary"
          sx={{ 
            borderRadius: "0.625rem",
            backgroundColor: activeBtn.cac ? "#536dfe" : "transparent",
            "&:hover": {
              backgroundColor: activeBtn.cac ? "#536dfe" : "rgba(83, 109, 254, 0.1)"
            }
          }}
        >
          <VuiTypography variant="button" color="white" fontWeight="regular">
            CPA x AOV x CVR
          </VuiTypography>
        </VuiButton>
        
        <VuiButton
          onClick={() => renderGraph("ccm")}
          variant={activeBtn.ccm ? "contained" : "outlined"}
          color="primary"
          sx={{ 
            borderRadius: "0.625rem",
            backgroundColor: activeBtn.ccm ? "#536dfe" : "transparent",
            "&:hover": {
              backgroundColor: activeBtn.ccm ? "#536dfe" : "rgba(83, 109, 254, 0.1)"
            }
          }}
        >
          <VuiTypography variant="button" color="white" fontWeight="regular">
            CPA x CAC x MER
          </VuiTypography>
        </VuiButton>
        
        <VuiButton
          onClick={() => renderGraph("spcv")}
          variant={activeBtn.spcv ? "contained" : "outlined"}
          color="primary"
          sx={{ 
            borderRadius: "0.625rem",
            backgroundColor: activeBtn.spcv ? "#536dfe" : "transparent",
            "&:hover": {
              backgroundColor: activeBtn.spcv ? "#536dfe" : "rgba(83, 109, 254, 0.1)"
            }
          }}
        >
          <VuiTypography variant="button" color="white" fontWeight="regular">
            Spend x Purchase Conversion Value
          </VuiTypography>
        </VuiButton>
      </Stack>
      
      <VuiBox
        sx={{
          height: "500px",
          width: "100%",
          background: "rgba(0, 0, 0, 0.2)",
          borderRadius: "20px",
          p: 2
        }}
      >
        <Chart
          title={"Facebook Overview"}
          options={options}
          series={seriesBar}
          type={"bar"}
          key={"bar"}
          width="100%"
          height="100%"
        />
      </VuiBox>
    </VuiBox>
  );
};

export default FBOChart;
