/** 
=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Redux
import { useSelector } from "react-redux";
import Loading from "components/shared-components/Loading";
import WarningMessage from "../warning";

import { HeaderWidgets } from "./HeaderWidgets";
import FBOChart from "views/app-views/dashboards/facebook/FBOChart";
import { FacebookOverview } from "views/app-views/dashboards/facebook/FacebookOverview";
import { useFeatureFlag } from "hooks/useFeatureFlag";

function FacebookDashboard() {
  const { values } = breakpoints;
  const selectedMer = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );
  const { isFeatureEnabled } = useFeatureFlag();
  const facebookData = useSelector((state: any) => state.facebookData.data);

  if (!selectedMer?.id) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container mt={10} justifyContent="center">
          <Grid item xs={12} md={6}>
            <WarningMessage
              text="No merchant account setup"
              detail="Please add a merchant account to view your dashboard"
              url="app/settings/add-account"
              actionText="Add Account"
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }

  if (!isFeatureEnabled("enabled_facebook_dashboard", false)) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container mt={10} justifyContent="center">
          <Grid item xs={12} md={6}>
            <WarningMessage
              text="404"
              detail="There's nothing here"
              url="/app/"
              actionText="Back to Dashboard"
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    )
  }

  if (!selectedMer?.facebookConnected) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container mt={10} justifyContent="center">
          <Grid item xs={12} md={6}>
            <WarningMessage
              text="Facebook not connected"
              detail="Please connect your Facebook account to view this dashboard"
              url="app/settings/store-details"
              actionText="Connect Facebook"
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Facebook Dashboard
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid container spacing={3}>
            <HeaderWidgets />
          </Grid>
          <Grid item xs={12} mb={3}>
            {facebookData?.graphData?.length > 0 && (
              <FBOChart
                FBOGraphData={facebookData?.graphData || []}
              />
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <VuiBox mb={3}>
                <FacebookOverview
                  title="Facebook Overview"
                  facebookData={facebookData}
                  loading={facebookData?.overview?.loading || false}
                  timezone={selectedMer?.timezone}
                  currency={selectedMer?.currency}
                />
              </VuiBox>
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FacebookDashboard; 