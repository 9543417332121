import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { useApi } from "hooks/useAPI";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { SalesService } from "services/SalesService";
import { type Merchant } from "types/merchant";
import { getLocalTime } from "utils/getLocalTime";
import { useFeatureFlag } from "hooks/useFeatureFlag";
interface DailyProfitData {
  date: string;
  grossRevenue: number;
  discount: number;
  shipping: number;
  tax: number;
  orderRevenue: number;
  shippingPaid: number;
  taxPaid: number;
  cost: number;
  productMargin: number;
  productMarginPercentage: number;
  adSpend: number;
  adSpendPercentage: number;
  pickAndPack: number;
  opex: number;
  contributionMargin: number;
  contributionMarginPercentage: number;
  paymentProcessingFee: number;
  profit: number;
}

interface DailyProfitResponse {
  data: DailyProfitData[];
}

function DailyProfitReport() {
  const { get } = useApi();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DailyProfitData[]>([]);

  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const { startDate, endDate } = useSelector((state: any) => state.common);

  const monthNames: Record<string, string> = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };

  const formatDate = (dateString: string): string => {
    if (dateString === 'total') return 'Total';

    const parts = dateString.split("-");
    if (parts.length === 3) {
      // Format: YYYY-MM-DD -> Month DD
      return `${monthNames[parts[1]] || parts[1]} ${parts[2]}`;
    } else if (parts.length === 2) {
      // Format: YYYY-MM -> Month YYYY
      return `${monthNames[parts[1]] || parts[1]} ${parts[0]}`;
    } else {
      return dateString;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedMerchant?.id || !startDate || !endDate) return;

      const salesService = new SalesService(get);
      const { startDateString, endDateString } = getLocalTime(startDate, endDate);

      setLoading(true);
      try {
        const result = await salesService.getDailyProfitReport({
          merchantId: selectedMerchant.id,
          start: startDateString,
          end: endDateString,
        }) as DailyProfitResponse;
        setData(result.data);
      } catch (error) {
        console.error("Error fetching daily profit report:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [selectedMerchant, startDate, endDate]);

  const formatCurrency = (value: number): string => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: selectedMerchant.currency || "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <VuiBox p={3}>
                <VuiBox mb={3}>
                  <VuiTypography variant="h2" color="white" fontWeight="bold">
                    {loading ? "Loading " : ""}Daily Profit Report
                  </VuiTypography>
                </VuiBox>
                <TableContainer 
                  component={Paper}
                  sx={{
                    maxWidth: "100%",
                    overflowX: "auto"
                  }}
                >
                  <Table sx={{ tableLayout: "fixed", minWidth: "1800px" }}>
                    <TableHead sx={{ display: "contents" }}>
                      <TableRow>
                        <TableCell align="center" sx={{ width: '80px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Date
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '140px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Gross Sales
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (Discounts)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Shipping
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Tax
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '140px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Order Revenue
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (Shipping Paid)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (Taxes Paid)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (COGS)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (Payment Processing Fee)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '140px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Product Margin
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '100px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Product Margin %
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (Ad spend)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '100px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Ad spend %
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '110px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (Pick & Pack)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '140px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Contribution Margin
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '100px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Contribution Margin %
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '100px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            (OPEX)
                          </VuiTypography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '140px !important' }}>
                          <VuiTypography variant="caption" color="text">
                            Profit
                          </VuiTypography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => {
                        const typographyProps = {
                          variant: "button" as const,
                          color: "white" as const,
                          fontWeight: row.date === 'total' ? 'bold' as const : 'regular' as const
                        };

                        return (
                          <TableRow
                            key={row.date}
                            sx={row.date === 'total' ? {
                              backgroundColor: 'rgba(26, 111, 238, 0.2)',
                              fontWeight: 'bold'
                            } : {}}
                          >
                            <TableCell align="center">
                              <VuiTypography {...typographyProps}>
                                {formatDate(row.date)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.grossRevenue)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.discount)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.shipping)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.tax)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.orderRevenue)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.shippingPaid)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.taxPaid)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.cost)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.paymentProcessingFee)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.productMargin)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="center">
                              <VuiTypography {...typographyProps}>
                                {row.productMarginPercentage}%
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.adSpend)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="center">
                              <VuiTypography {...typographyProps}>
                                {row.adSpendPercentage}%
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.pickAndPack)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.contributionMargin)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="center">
                              <VuiTypography {...typographyProps}>
                                {row.contributionMarginPercentage}%
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.opex)}
                              </VuiTypography>
                            </TableCell>
                            <TableCell align="right">
                              <VuiTypography {...typographyProps}>
                                {formatCurrency(row.profit)}
                              </VuiTypography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DailyProfitReport; 