import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MerchantService from "services/MerchantService";
import {
  setMerchants,
  selectedMerchant as setSelectedMerchant,
} from "redux/actions";
import { useApi } from "hooks/useAPI";
import ChannelSelector from "components/shared-components/ChannelSelector";
import { Merchant } from "types/merchant";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import VuiButton from "components/VuiButton";
import { useSnackbar } from "notistack";
import FormField from "./components/FormField";
import { TimezoneSelect } from "./components/TimezoneSelect";
import { useFeatureFlag } from "hooks/useFeatureFlag";
interface ShopifyStoreSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ShopifyStoreSettingsModal({
  isOpen,
  onClose,
}: ShopifyStoreSettingsModalProps) {
  const { getToken } = useApi();
  const { isFeatureEnabled } = useFeatureFlag();
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState<Partial<Merchant>>({
    name: "",
    currency: "",
    shopifyTimeZone: "",
    defaultShippingPercentage: 0,
    pickAndPackFee: 0,
    monthlyOpex: 0,
    taxPercentage: 0,
    paymentProcessingFeePercentage: 0,
    paymentProcessingFixedFee: 0,
  });

  const dispatch = useDispatch();
  const merchants = useSelector(
    (state: any) => state.merchants.merchant as Merchant[]
  );
  const selectedMerchant = useSelector(
    (state: any) => state.merchants.selectedMerchant as Merchant
  );

  useEffect(() => {
    if (selectedMerchant) {
      setSelectedChannels(selectedMerchant.shopifySalesChannels);
      setFormValues({
        name: selectedMerchant.name,
        currency: selectedMerchant.currency,
        defaultShippingPercentage: selectedMerchant.defaultShippingPercentage,
        pickAndPackFee: selectedMerchant.pickAndPackFee,
        shopifyTimeZone: selectedMerchant.shopifyTimeZone,
        monthlyOpex: selectedMerchant.monthlyOpex,
        taxPercentage: selectedMerchant.taxPercentage,
        paymentProcessingFeePercentage: selectedMerchant.paymentProcessingFeePercentage,
        paymentProcessingFixedFee: selectedMerchant.paymentProcessingFixedFee,
      });
    }
  }, [selectedMerchant]);

  const handleInputChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    };

  const handleTimezoneChange = (timezone: string) => {
    setFormValues((prev) => ({
      ...prev,
      shopifyTimeZone: timezone,
    }));
  };

  const onUpdate = async () => {
    const token = getToken ? await getToken() : undefined;
    if (!token) return;

    try {
      const values = {
        ...formValues,
        id: selectedMerchant.id,
        shopifyTimeZone: formValues.shopifyTimeZone,
        shopifySalesChannels: selectedChannels,
      };

      const resp = await MerchantService.updateMerchant(values, token);
      enqueueSnackbar("Updated Successfully!", { variant: "success" });

      const updatedMerchants = merchants.map((m) =>
        m.id === resp.id ? { ...resp, roles: (m as any).roles } : m
      );

      dispatch(setMerchants(updatedMerchants));
      dispatch(setSelectedMerchant({ ...resp, roles: (selectedMerchant as any).roles }));
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message || "Update failed", { variant: "error" });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.87)",
          color: "white",
        },
      }}
    >
      <DialogTitle sx={{ color: "white !important" }}>
        Edit Shopify Details
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={6}>
          <Stack direction="column" spacing={2}>
            <FormField
              label="Store Name"
              placeholder="Store Name"
              onChange={handleInputChange("name")}
              value={formValues.name}
            />
            <Box>
              <ChannelSelector
                selectedChannels={selectedChannels || []}
                onChange={setSelectedChannels}
              />
            </Box>
            <FormField
              label="Currency"
              placeholder="Currency"
              onChange={handleInputChange("currency")}
              value={formValues.currency}
            />
            <Box>
              <TimezoneSelect
                defaultValue={selectedMerchant?.shopifyTimeZone}
                onChange={handleTimezoneChange}
              />
            </Box>
            <Stack direction="row" spacing={2}>
              <FormField
                label="Shipping Percentage"
                placeholder="Shipping %"
                type="number"
                onChange={handleInputChange("defaultShippingPercentage")}
                value={formValues.defaultShippingPercentage}
              />
              <FormField
                label="Pick and Pack Fee"
                placeholder="Fee"
                type="number"
                onChange={handleInputChange("pickAndPackFee")}
                value={formValues.pickAndPackFee}
              />
            </Stack>
            <FormField
              label="Monthly Opex"
              placeholder="Monthly Opex"
              onChange={handleInputChange("monthlyOpex")}
              value={formValues.monthlyOpex}
            />
            <FormField
              label="Tax Percentage"
              placeholder="Tax %"
              type="number"
              onChange={handleInputChange("taxPercentage")}
              value={formValues.taxPercentage}
            />
            {isFeatureEnabled("enable_payment_processing_fee_setting", false) && (
              <>
                <Stack direction="row" spacing={2}>
                  <FormField
                    label="Payment Processing Fee %"
                    placeholder="2.5"
                    type="number"
                    onChange={handleInputChange("paymentProcessingFeePercentage")}
                    value={formValues.paymentProcessingFeePercentage}
                  />
                  <FormField
                    label="Payment Processing Fixed Fee"
                    placeholder="0.03"
                    type="number"
                    onChange={handleInputChange("paymentProcessingFixedFee")}
                    value={formValues.paymentProcessingFixedFee}
                  />
                </Stack>
              </>
            )}
            <Box></Box>
          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <VuiButton variant="outlined" onClick={onClose}>
          Cancel
        </VuiButton>
        {/* @ts-ignore */}
        <VuiButton variant="contained" onClick={onUpdate}>
          Update
        </VuiButton>
      </DialogActions>
    </Dialog>
  );
}
