import { Grid, Card } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Icon,
} from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiPagination from "components/VuiPagination";
import VuiInput from "components/VuiInput";
import breakpoints from "assets/theme/base/breakpoints";
import { useSelector } from "react-redux";
import { useApi } from "hooks/useAPI";
import { useEffect, useState } from "react";
import { SubuserService, Subuser } from "services/SubuserService";
import { type Merchant } from "types/merchant";
import AccessDeniedCard from "components/shared-components/AccessDeniedCard";
import { hasAdminAccess } from "utils/roleAccess";
import VuiButton from "components/VuiButton";
import { useSnackbar } from "notistack";

const DEFAULT_PAGE_SIZE = 50;

const SubUsers: React.FC = () => {
  const { values } = breakpoints;
  const { get, delete: del } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [subUsers, setSubUsers] = useState<Subuser[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const userRole = useSelector((state: any) => state.merchants.selectedMerchant.roles?.name);
  const hasAccess = hasAdminAccess(userRole);

  const fetchSubUsers = async (page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
    if (!hasAccess) return;
    if (selectedMerchant?.id) {
      setLoading(true);
      try {
        const subuserService = new SubuserService(get, del);
        const response = await subuserService.getSubusers({
          merchantId: selectedMerchant.id,
          page,
          limit: pageSize,
          sortBy: "createdAt",
        });
        setSubUsers(response.results);
        setPagination({
          ...pagination,
          current: page,
          pageSize,
          total: response.totalResults,
        });
      } catch (error) {
        console.error("Error fetching subusers:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSubUsers(pagination.current, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMerchant]);

  const entriesStart = (pagination.current - 1) * pagination.pageSize + 1;
  const entriesEnd = Math.min(
    pagination.current * pagination.pageSize,
    pagination.total
  );
  
  const currentPage = pagination.current;
  const totalPages = Math.ceil(pagination.total / pagination.pageSize);

  const handleTableChange = (newPagination: any) => {
    fetchSubUsers(newPagination.current, newPagination.pageSize);
  };

  const handleDelete = async (roleToken: string, userName: string) => {
    if (!window.confirm(`Are you sure you want to remove ${userName}?`)) {
      return;
    }

    if (selectedMerchant?.id) {
      try {
        const subuserService = new SubuserService(get, del);
        await subuserService.deleteSubuser(roleToken, selectedMerchant.id);
        enqueueSnackbar("User removed successfully", { variant: "success" });
        fetchSubUsers(pagination.current, pagination.pageSize);
      } catch (error) {
        console.error("Error deleting subuser:", error);
        enqueueSnackbar("Failed to remove user", { variant: "error" });
      }
    }
  };
  if (!hasAccess) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox py={3}>
          <AccessDeniedCard
            title="Access Denied"
            message="Only owners can access and manage sub-users of selected merchant. Please contact your administrator for access."
          />
        </VuiBox>
      </DashboardLayout>
    );
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={24}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                {loading && "Loading Sub-users"}
                {!loading && subUsers.length === 0 && "No sub-users found"}
                {!loading &&
                  subUsers.length > 0 &&
                  `${pagination.total?.toLocaleString()} Sub-users`}
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={24}>
            <Card>
              <VuiBox p={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ tableLayout: "fixed" }}>
                    <TableHead sx={{ display: "contents" }}>
                      <TableRow>
                        <TableCell>
                          <VuiTypography variant="caption" color="text">
                            Name
                          </VuiTypography>
                        </TableCell>
                        <TableCell sx={{ width: '25%' }}>
                          <VuiTypography variant="caption" color="text">
                            Email
                          </VuiTypography>
                        </TableCell>
                        <TableCell>
                          <VuiTypography variant="caption" color="text">
                            Role
                          </VuiTypography>
                        </TableCell>
                        <TableCell>
                          <VuiTypography variant="caption" color="text">
                            Actions
                          </VuiTypography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subUsers.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>
                            <VuiTypography variant="button" color="white">
                              {user.name}
                            </VuiTypography>
                          </TableCell>
                          <TableCell>
                            <VuiTypography variant="button" color="white">
                              {user.email}
                            </VuiTypography>
                          </TableCell>
                          <TableCell>
                            <VuiTypography variant="button" color="white">
                              {user.role}
                            </VuiTypography>
                          </TableCell>
                          <TableCell>
                            {/* @ts-ignore */}
                            <VuiButton
                              color="error"
                              size="small"
                              onClick={() => handleDelete(user.roleId, user.name)}
                            >
                              Remove
                            </VuiButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <VuiBox
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", md: "center" }}
                    p={3}
                  >
                    <VuiBox mb={{ xs: 3, md: 0 }}>
                      <VuiTypography
                        variant="button"
                        color="white"
                        fontWeight="regular"
                      >
                        Showing {entriesStart} to {entriesEnd} of{" "}
                        {pagination.total} entries
                      </VuiTypography>
                    </VuiBox>
                    {totalPages > 1 && (
                      //@ts-ignore
                      <VuiPagination variant="gradient" color="info">
                        {currentPage > 1 && (
                          //@ts-ignore
                          <VuiPagination
                            item
                            onClick={() =>
                              handleTableChange({
                                ...pagination,
                                current: currentPage - 1,
                              })
                            }
                          >
                            <Icon sx={{ fontWeight: "bold" }}>
                              chevron_left
                            </Icon>
                          </VuiPagination>
                        )}

                        {totalPages <= 6 ? (
                          [...Array(totalPages)].map((_, index) => (
                            //@ts-ignore
                            <VuiPagination
                              key={index + 1}
                              item
                              active={currentPage === index + 1}
                              onClick={() =>
                                handleTableChange({
                                  ...pagination,
                                  current: index + 1,
                                })
                              }
                            >
                              {index + 1}
                            </VuiPagination>
                          ))
                        ) : (
                          <VuiBox width="5rem" mx={1}>
                            <VuiInput
                              //@ts-ignore
                              type="number"
                              inputProps={{
                                min: 1,
                                max: totalPages,
                              }}
                              value={currentPage}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const page = parseInt(e.target.value);
                                if (page >= 1 && page <= totalPages) {
                                  handleTableChange({
                                    ...pagination,
                                    current: page,
                                  });
                                }
                              }}
                            />
                          </VuiBox>
                        )}

                        {currentPage < totalPages && (
                          //@ts-ignore
                          <VuiPagination
                            item
                            onClick={() =>
                              handleTableChange({
                                ...pagination,
                                current: currentPage + 1,
                              })
                            }
                          >
                            <Icon sx={{ fontWeight: "bold" }}>
                              chevron_right
                            </Icon>
                          </VuiPagination>
                        )}
                      </VuiPagination>
                    )}
                  </VuiBox>
                </TableContainer>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default SubUsers;
