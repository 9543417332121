import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { useApi } from "hooks/useAPI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GoogleAdsService } from "services/GoogleAdsService";
import { Merchant } from "types/merchant";
import { getLocalTime } from "utils/getLocalTime";

export const GoogleROASWidget = () => {
  const { get } = useApi();
  const selectedMerchant: Merchant = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const { startDate, endDate } = useSelector((state: any) => state.common);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [compareValue, setCompareValue] = useState(0);
  useEffect(() => {
    const googleAdsService = new GoogleAdsService(get);
    const { startDateString, endDateString } = getLocalTime(startDate, endDate);
    setLoading(true);
    googleAdsService
      .getROAS({
        merchantId: selectedMerchant.id,
        startDate: startDateString,
        endDate: endDateString,
      })
      .then((response) => {
        setValue(response.roas);
        setCompareValue(response.compareValue);
        setLoading(false);
      });
  }, [selectedMerchant, startDate, endDate]);

  const calculatePercentageChange = () => {
    const downIsGood = false;

    if (compareValue === 0 || compareValue === null || compareValue === undefined) {
      return {
        color: "info" as "info",
        text: "",
      };
    }

    const percentageChange = ((value - compareValue) / compareValue) * 100;
    const isPositiveChange = percentageChange > 0;
    const isGoodChange = downIsGood ? !isPositiveChange : isPositiveChange;

    return {
      color: isGoodChange ? "success" : "error" as "success" | "error",
      text: `${isPositiveChange ? "+" : ""}${percentageChange.toFixed(1)}%`,
    };
  };

  return (
    <MiniStatisticsCard
      title={{ text: "Google ROAS", fontWeight: "bold" }}
      count={value.toFixed(2)}
      percentage={calculatePercentageChange()}
      loading={loading}
    />
  );
};
