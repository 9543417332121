export interface IMonthlyTarget {
  id: string;
  merchantId: string;
  date: Date;
  revenue: number;
  aov: number;
  totalSpend: number;
  contMargin: number;
  mer: number;
  newCustomers: number;
  returningCustomers: number;
  facebookRoas: number;
  amer: number;
  totalOrders: number;
  cac: number;
  googleRoas: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDailyTarget {
  id: string;
  merchantId: string;
  monthlyTargetId: string;
  date: Date;
  revenue: number;
  aov: number;
  totalSpend: number;
  contMargin: number;
  mer: number;
  newCustomers: number;
  returningCustomers: number;
  facebookRoas: number;
  amer: number;
  totalOrders: number;
  cac: number;
  googleRoas: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface TargetValues {
  revenue: number;
  aov: number;
  totalSpend: number;
  contMargin: number;
  mer: number;
  newCustomers: number;
  returningCustomers: number;
  facebookRoas: number;
  amer: number;
  totalOrders: number;
  cac: number;
  googleRoas: number;
}

export const TargetFields = [
  "revenue",
  "aov",
  "totalSpend",
  "contMargin",
  "mer",
  "newCustomers",
  "returningCustomers",
  "facebookRoas",
  "amer",
  "totalOrders",
  "cac",
  "googleRoas",
];

export const titleForField = (field: string) => {
  switch (field) {
    case "revenue":
      return "Revenue";
    case "aov":
      return "AOV";
    case "totalSpend":
      return "Total Spend";
    case "contMargin":
      return "Contribution Margin";
    case "mer":
      return "MER";
    case "newCustomers":
      return "New Customers";
    case "returningCustomers":
      return "Returning Customers";
    case "facebookRoas":
      return "Facebook ROAS";
    case "amer":
      return "AMER";
    case "totalOrders":
      return "Total Orders";
    case "cac":
      return "CAC";
    case "googleRoas":
      return "Google ROAS";
    default:
      return field.charAt(0).toUpperCase() + field.slice(1);
  }
};
